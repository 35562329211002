import Button from '@/components/button';
import { Form } from '@/components/form';
import { InputField, SelectField, TextArea } from '@/components/input';
import { GetTopics } from '@/core/hooks/topics';
import React, { Fragment, useEffect } from 'react';

import 'react-quill/dist/quill.snow.css';
import { z } from 'zod';
import { Dialog, Transition } from '@headlessui/react';
import { QuestionPayload } from '@/core/types/questions';
import { EditQuestionInfo } from '@/core/hooks/question';
import IconPencil from '@/components/Icon/IconPencil';

const questionSchema = z.object({
    title: z.string().min(1, { message: 'Title is required' }),
    description: z.string().min(1, { message: 'Description is required' }),
    topicId: z.string().min(1, { message: 'Topic is required' }),
    priority:z.string().min(1, { message: 'Priority is required' }),
});

export default function UpdateQuestion({ refresh,   question }: any) {
    const [modal, setModal] = React.useState(false);
    const { loadingEditQuestion, handleEditQuestion } = EditQuestionInfo();

    const { loadingTopics, handleGetTopics, topics } = GetTopics();
    const query = location.search;
     
    useEffect(() => {
        handleGetTopics(query);
    }, [query]);

    return (
        <div>
            <div className="">
                <div className="flex items-center justify-center">
                    <button
                        type="button"
                        onClick={() => setModal(true)}
                        className="bg-warning text-gray-200 p-1 rounded"
                    >
                        <IconPencil className="w-4" />
                    </button>
                </div>
                <Transition appear show={modal} as={Fragment}>
                    <Dialog
                        as="div"
                        open={modal}
                        onClose={() => setModal(false)}
                    >
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0" />
                        </Transition.Child>
                        <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                            <div className="flex items-start justify-center min-h-screen px-4">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <Dialog.Panel
                                        as="div"
                                        className="panel border-0 p-0 rounded-lg overflow-hidden my-8 w-full max-w-xl text-black dark:text-white-dark"
                                    >
                                        <div className="flex bg-[#fbfbfb] dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                                            <div className="text-lg flex flex-row justify-center w-full">
                                                Edit Question
                                            </div>
                                        </div>
                                        <div className="p-5">
                                            <Form<
                                                QuestionPayload,
                                                typeof questionSchema
                                            >
                                                schema={questionSchema}
                                                onSubmit={async data => {
                                                    data.priority = parseInt(data?.priority);
                                                    await handleEditQuestion(
                                                        data,
                                                        question?.id
                                                    );

                                                    refresh(
                                                        (prev: any) => !prev
                                                    );
                                                    setModal(false);
                                                }}
                                                className="mt-3"
                                            >
                                                {({ register, formState }) => (
                                                    <>
                                                        <div className="px-4 grid  gap-4 text-sm mb-3">
                                                            
                                                        <SelectField
                                                                placeholder="Select Topic"
                                                                isLoading={
                                                                    loadingTopics ||
                                                                    loadingEditQuestion
                                                                }
                                                                defaultValue={
                                                                    question?.topics
                                                                        ?.id
                                                                }
                                                                label="Topic"
                                                                error={
                                                                    formState
                                                                        .errors
                                                                        .topicId
                                                                }
                                                                options={(
                                                                    topics?.list ||
                                                                    []
                                                                ).map(
                                                                    (app: {
                                                                        id: any;
                                                                        name: any;
                                                                    }) => ({
                                                                        value: app?.id,
                                                                        text: app?.name,
                                                                    })
                                                                )}
                                                                registration={register(
                                                                    'topicId'
                                                                )}
                                                            />
                                                              <InputField
                                                              type='text'
                                                                isLoading={
                                                                    loadingEditQuestion
                                                                }
                                                                defaultValue={question?.priority}
                                                                placeholder=" Priority 1,2,3,4, or 5 "
                                                                label="Priority"
                                                                error={
                                                                    formState
                                                                        .errors
                                                                        .title
                                                                }
                                                                registration={register(
                                                                    'priority'
                                                                )}
                                                            />
                                                           
                                                            <TextArea
                                                               
                                                                isLoading={
                                                                    loadingEditQuestion
                                                                }
                                                                placeholder="Topic title"
                                                                label="Topic Name"
                                                                defaultValue={
                                                                    question?.title
                                                                }
                                                                error={
                                                                    formState
                                                                        .errors
                                                                        .title
                                                                }
                                                                registration={register(
                                                                    'title'
                                                                )}
                                                            />


                                                        </div>
                                                        <div className=' px-4 '>

                                                            <TextArea
                                                                
                                                                isLoading={
                                                                    loadingEditQuestion
                                                                }
                                                                placeholder="Description"
                                                                label="Description"
                                                                defaultValue={
                                                                    question?.description
                                                                }
                                                                className='h-20 '
                                                                error={
                                                                    formState
                                                                        .errors
                                                                        .description
                                                                }
                                                                registration={register(
                                                                    'description'
                                                                )}
                                                            />
                                                            </div>

                                                        <div className="my-3 flex justify-end">
                                                            <Button
                                                                disabled={
                                                                    loadingEditQuestion
                                                                }
                                                            >
                                                                Submit
                                                            </Button>
                                                        </div>
                                                    </>
                                                )}
                                            </Form>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition>
            </div>
        </div>
    );
}
