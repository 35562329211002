import React, { useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import Button from '@/components/button';
import { InputField, SelectField } from '@/components/input';
import IconPlus from '@/components/Icon/IconPlus';
import { EditCategoryInfo, GetCategories, useCreateCategory } from '@/core/hooks/category';
import IconPencil from '@/components/Icon/IconPencil';
import { CategoryPayload } from '@/core/types/category';
import IconX from '@/components/Icon/IconX';

interface AddCategoryProps {
    refresh: (prev: any) => void;
    data: any;
}

export const UpdateCategory: React.FC<AddCategoryProps> = ({
    refresh,
    data,
}) => {
    const [modal, setModal] = React.useState(false);
    const { loadingEditCategory, handleEditCategory } = EditCategoryInfo();
    const [categoryData, setCategoryData] = useState<any>({
        name: '',
        subcategoryId: '',
        skills_required: [{ name: '' }],
    });

    const handleInputChange = (
        field: string,
        value: string,
        index?: number
    ) => {
        setCategoryData((prev: { skills_required: any[] }) => {
            if (index !== undefined && prev.skills_required[index]) {
                const updatedSkills = [...prev.skills_required];
                updatedSkills[index] = { name: value };
                return { ...prev, skills_required: updatedSkills };
            } else {
                return { ...prev, [field]: value };
            }
        });
       
    };

    const addSkill = () => {
        setCategoryData((prev: { skills_required: any }) => ({
            ...prev,
            skills_required: [...prev.skills_required, { name: '' }],
        }));
    };

    useEffect(() => {
        setCategoryData({
            name: data.name,
            sub_category: data.sub_category,
            skills_required: data.skills_required.map(
                (skill: { name: any }) => ({ name: skill.name })
            ),
        });
    }, [data]);

    const removeSkill = (index: number) => {
        setCategoryData((prev: { skills_required: any }) => {
            const updatedSkills = prev.skills_required.filter(
                (_: any, i: number) => i !== index
            );
            return { ...prev, skills_required: updatedSkills };
        });
    };

    const submit = async (e: any) => {
        e.preventDefault();
     await handleEditCategory(categoryData, data.id);
        refresh((prev: any) => !prev);
        setModal(false);
    };
    const { loadingCategories, handleGetCategories, categories } =
    GetCategories();

useEffect(() => {
    handleGetCategories();
}, []);

    return (
        <div className="">
            <div className="flex items-center justify-center">
            <button
                    type="button"
                    onClick={() => setModal(true)}
                    className="bg-warning text-gray-200 p-1 rounded"
                >
                    <IconPencil className="w-4" />
                </button>
            </div>
            <Transition appear show={modal} as={Fragment}>
                <Dialog as="div" open={modal} onClose={() => setModal(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0" />
                    </Transition.Child>
                    <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                        <div className="flex items-start justify-center min-h-screen px-4">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    as="div"
                                    className="panel border-0 p-0 rounded-lg overflow-hidden my-8 w-full max-w-xl text-black dark:text-white-dark"
                                >
                                    <div className="flex bg-[#fbfbfb] dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                                        <div className="text-lg flex flex-row justify-center w-full">
                                            Add Category
                                        </div>
                                    </div>
                                    <div className="p-5">
                                        <form onSubmit={submit}>
                                            <div className="px-4 grid grid-cols-2 gap-4 text-sm mb-3">
                                                <InputField
                                                    type="text"
                                                    isLoading={
                                                        loadingEditCategory
                                                    }
                                                    placeholder="e.g., Category Name"
                                                    label="Category Name"
                                                    defaultValue={data?.name}
                                                    onChange={e =>
                                                        handleInputChange(
                                                            'name',
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                                              <SelectField
                                    placeholder="Select   Sub Category"
                                    isLoading={
                                        loadingEditCategory ||
                                        loadingCategories
                                    }
                                   
                                    label=" Sub Category"

                                    onChange={e =>
                                        handleInputChange(
                                            'subcategoryId',
                                            e.target.value
                                        )
                                    }
                                    options={(categories?.list || [])
                                        .filter(
                                            (topic: { status: string }) =>
                                                topic.status === 'active'
                                        )
                                        .map((app: { id: any; name: any }) => ({
                                            value: app?.id,
                                            text: app?.name,
                                        }))}
                                />

                                                <div>
                                                    <label
                                                        htmlFor="skills"
                                                        className="block text-sm font-medium text-gray-700"
                                                    >
                                                        Skills Required
                                                    </label>
                                                    {categoryData.skills_required.map(
                                                        (
                                                            skill: any,
                                                            index: any
                                                        ) => (
                                                            <div
                                                                key={index}
                                                                className="flex items-center mt-2"
                                                            >
                                                                <InputField
                                                                    type="text"
                                                                    isLoading={
                                                                        loadingEditCategory
                                                                    }
                                                                    defaultValue={
                                                                        skill.name
                                                                    }
                                                                    placeholder={`Skill #${
                                                                        index +
                                                                        1
                                                                    }`}
                                                                    label=""
                                                                    onChange={e =>
                                                                        handleInputChange(
                                                                            'skills_required',
                                                                            e
                                                                                .target
                                                                                .value,
                                                                            index
                                                                        )
                                                                    }
                                                                />
                                                                {index > 0 && (
                                                                    <button
                                                                        type="button"
                                                                        onClick={() =>
                                                                            removeSkill(
                                                                                index
                                                                            )
                                                                        }
                                                                        className="ml-2 text-red-500"
                                                                    >
                                                                        <IconX />
                                                                    </button>
                                                                )}
                                                            </div>
                                                        )
                                                    )}
                                                    <button
                                                        type="button"
                                                        onClick={addSkill}
                                                        className="mt-2 text-blue-500"
                                                    >
                                                        Add Skill
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="my-3 flex justify-end">
                                                <Button>Submit</Button>
                                            </div>
                                        </form>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
};
