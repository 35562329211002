// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sortable-ghost {

    opacity: 0.7
}

.dark .sortable-ghost {

    opacity: 0.4
}

.dark .sortable-drag {

    --tw-rotate: 10deg;

    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));

    --tw-bg-opacity: 1;

    background-color: rgb(14 23 38 / 1);

    background-color: rgb(14 23 38 / var(--tw-bg-opacity, 1))
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/scrumboard.css"],"names":[],"mappings":"AACI;;IAAA;AAAiB;;AAIjB;;IAAA;AAAiB;;AAIjB;;IAAA,kBAA8B;;IAA9B,+LAA8B;;IAA9B,kBAA8B;;IAA9B,mCAA8B;;IAA9B;AAA8B","sourcesContent":[".sortable-ghost {\n    @apply opacity-70;\n}\n\n.dark .sortable-ghost {\n    @apply opacity-40;\n}\n\n.dark .sortable-drag {\n    @apply rotate-[10deg] bg-black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
