import { useState } from 'react';
import { toast } from 'react-hot-toast';
import {
  createSocioEconomic,
  getSocioEconomicById,
  getSocioEconomicByUser,
  updateSocioEconomic,
  deleteSocioEconomic,
} from '@/core/api/social';

interface SocioEconomicPayload {
  parentsStatus: number;
  mainBreadwinner: number;
  householdDependents: number;
  houseOwnership: number;
  landOwnership: number;
  residenceType: number;
  roofingMaterial: number;
  householdIncome: number;
  governmentSupport: number;
  cookingEnergy: number;
  schoolDropoutReason: number;
}

export const useCreateSocioEconomic = () => {
  const [loading, setLoading] = useState(false);

  const handleCreate = async (payload: SocioEconomicPayload) => {
    try {
      setLoading(true);
      await createSocioEconomic(payload);
      toast.success('Socio-economic data created successfully');
    } catch (error: any) {
      toast.error(error.message || 'Error creating socio-economic data');
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    handleCreate,
  };
};

export const useFetchSocioEconomicById = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>(null);

  const handleFetchById = async (id: string) => {
    try {
      setLoading(true);
      const result = await getSocioEconomicById(id);
      setData(result);
    } catch (error: any) {
      toast.error(error.message || 'Error fetching socio-economic data');
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    data,
    handleFetchById,
  };
};

export const useFetchSocioEconomicByUser = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>(null);

  const handleFetchByUser = async (userId: string) => {
    try {
      setLoading(true);
      const result = await getSocioEconomicByUser(userId);
      setData(result);
    } catch (error: any) {
      toast.error(error.message || 'Error fetching user socio-economic data');
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    data,
    handleFetchByUser,
  };
};

export const useUpdateSocioEconomic = () => {
  const [loading, setLoading] = useState(false);

  const handleUpdate = async (id: string, payload: Partial<SocioEconomicPayload>) => {
    try {
      setLoading(true);
      await updateSocioEconomic(id, payload);
      toast.success('Socio-economic data updated successfully');
    } catch (error: any) {
      toast.error(error.message || 'Error updating socio-economic data');
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    handleUpdate,
  };
};

export const useDeleteSocioEconomic = () => {
  const [loading, setLoading] = useState(false);

  const handleDelete = async (id: string) => {
    try {
      setLoading(true);
      await deleteSocioEconomic(id);
      toast.success('Socio-economic data deleted successfully');
    } catch (error: any) {
      toast.error(error.message || 'Error deleting socio-economic data');
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    handleDelete,
  };
};
