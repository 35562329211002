import { useEffect, useState } from 'react';
import Button from '@/components/button';
import { Form } from '@/components/form';
import { InputField, SelectField } from '@/components/input';
import {
    GetMyAddress,
    getAddresses,
    useCreateAddress,
} from '@/core/hooks/address';
import { AddressType } from '@/core/types/address';
import { z } from 'zod';
import IconX from '@/components/Icon/IconX';
import DataTableV2, { TableColumnV2 } from '@/components/datatable/DataTable';
import { useLocation } from 'react-router-dom';
import formatDateToLongForm from '@/core/utils/DateFormattter';
import DeleteUserModal from '../users/modals/delete_modal';
import { TrashIcon } from '@heroicons/react/24/outline';
import { UpdateUser } from '../users/modals/Edit_user_modal';
import { capitalize } from '@mui/material';
import { AcademicInfoType } from '@/core/types/academic';
import { GetAcademics, GetMyAccademics } from '@/core/hooks/academics';
import { useAppSelector } from '@/store';
import { ExperienceType } from '@/core/types/experience';
import { GetExperiences } from '@/core/hooks/experience';
import { UpdateExperience } from './modal/Edit_experince';
import DeleteUserExperince from './modal/Delete_experince';
import { AddExperience } from './modal/Add_Experience';
import DataTablev3 from '@/components/datatable/Datatable2';



export default function ExperiencesInfo() {
    const { loadingCreate, handleCreate } = useCreateAddress();
    const [showForm, setShowForm] = useState(false);
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState<ExperienceType | null>(null);
    const [refresh, setRefresh] = useState(false);
    const location = useLocation();
    const query = location.search;
    const { userAccount:user }:any = useAppSelector((store) => store.user);

    const { loadingExperiences,handleGetExperiences,experiences} =GetExperiences();

    const toggleFormVisibility = () => {
        setShowForm(!showForm);
    };

    const closeForm = () => {
        setShowForm(false);
    };

    useEffect(() => {
        handleGetExperiences(query);
    }, [query, refresh]);
    const columns: TableColumnV2<ExperienceType>[] = [
        {
            title: 'Employer Name',
            accessor: 'employer_name',
            render: row => <p className="capitalize">{row?.employer_name}</p>,
        },
        {
            title: 'Country',
            accessor: 'country',
            render: row => <p className="capitalize">{row?.country}</p>,
        },
        {
            title: 'City',
            accessor: 'city',
            render: row => <p className="capitalize">{row?.city}</p>,
        },
        {
            title: 'Employer Website',
            accessor: 'employer_website',
            render: row => <p>{row?.employer_website}</p>,
        },
        {
            title: 'Start Date',
            accessor: 'start_date',
            render: row => <p>{formatDateToLongForm(row?.start_date)}</p>, 
        },
        {
            title: 'End Date',
            accessor: 'end_date',
            render: row => <p>{formatDateToLongForm(row?.end_date)}</p>, 
        },
        {
            title: 'Position',
            accessor: 'position',
            render: row => <p>{row?.position}</p>,
        },
        {
            title: 'Position Description',
            accessor: 'position_description',
            render: row => <p>{row?.roles_and_responsibilities}</p>,
        },
        {
            title: 'Type',
            accessor: 'type',
            render: row => <p>{row?.type}</p>,
        },
    
        {
            title: 'Date Created',
            accessor: 'created_at',
            render: row => <p>{formatDateToLongForm(row?.created_at)}</p>,
        },
        {
            title: 'Actions',
            accessor: 'actions',
            render: row => (
                <div className="flex gap-2 justify-center">
                    <button
                        onClick={() => {
                            setSelected(row);
                            setOpen(true);
                        }}
                        className="bg-red-700 text-gray-200 p-1 rounded"
                    >
                        <TrashIcon className="w-4" />
                    </button>
                    
                    <UpdateExperience experience={row} refresh={setRefresh} />
                </div>
            ),
        },
    ];
    
    return (
        <div className="panel py-4 ">
            <div>
                <div className='flex flex-row justify-end'>
                <AddExperience refresh = {setRefresh}/>
                </div>
            
            </div>
            <div className="">
                <DataTablev3
                    columns={columns}
                    previousPage={0}
                    nextPage={0}
                    currentPage={1}
                    data={experiences?.list ?? []}
                    total={experiences?.total ?? 0}
                    lastPage={experiences?.lastPage ?? 0}
                    isLoading={loadingExperiences}
                />
                <DeleteUserExperince
                    refresh={setRefresh}
                    selected={selected}
                    open={open}
                    setOpen={setOpen}
                    title={'Delete Experience'}
                />
            </div>
        </div>
    );
}
