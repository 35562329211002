import { useEffect, useState } from 'react';
import {
    useCreateAddress,
} from '@/core/hooks/address';
import { TableColumnV2 } from '@/components/datatable/DataTable';
import { useLocation } from 'react-router-dom';
import { TrashIcon } from '@heroicons/react/24/outline';
import { useAppSelector } from '@/store'
import { GetLanguages } from '@/core/hooks/languages';
import { LanguageType } from '@/core/types/languages';
import DeleteUserLanguages from './modal/Delete_Language';
import { AddLanguage } from './modal/Add_language';
import { UpdateLanguage } from './modal/Edit_language';
import DataTablev3 from '@/components/datatable/Datatable2';
import formatDateToLongForm from '@/core/utils/DateFormattter';



export default function LanguageInfo() {
    const { loadingCreate, handleCreate } = useCreateAddress();
    const [showForm, setShowForm] = useState(false);
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState<LanguageType | null>(null);
    const [refresh, setRefresh] = useState(false);
    const location = useLocation();
    const query = location.search;
    const { userAccount:user }:any = useAppSelector((store) => store.user);

    const { loadingLanguages,handleGetLanguages,languages} =GetLanguages();

    const toggleFormVisibility = () => {
        setShowForm(!showForm);
    };

    const closeForm = () => {
        setShowForm(false);
    };

    useEffect(() => {
        handleGetLanguages(query);
    }, [query, refresh]);
    const columns: TableColumnV2<LanguageType>[] = [
        {
            title: 'Language Name',
            accessor: 'name',
            render: row => <p className="capitalize">{row?.name}</p>,
        },
        {
            title: 'Verbal Level',
            accessor: 'verbal_level',
            render: row => <p className="capitalize">{row?.verbal_level}</p>,
        },
        {
            title: 'Written_level',
            accessor: 'written_level',
            render: row => <p className="capitalize">{row?.written_level}</p>,
        },
        {
            title: 'Date added',
            accessor: 'created_at',
            render: row => <p className="capitalize">{formatDateToLongForm(row?.created_at)}</p>,
        },
        {
            title: 'Actions',
            accessor: 'actions',
            render: row => (
                <div className=" flex flex-row gap-2 ">
                    <button
                        onClick={() => {
                            setSelected(row);
                            setOpen(true);
                        }}
                        className="bg-red-700 text-gray-200 p-1 rounded"
                    >
                        <TrashIcon className="w-4" />
                    </button>
                    {/* Replace UpdateAcademicInfo with your component for updating experience information */}
                    <UpdateLanguage language
                    ={row} refresh={setRefresh} />
                </div>
            ),
        },
    ];
    
    return (
        <div className="panel py-4 ">
            <div>
                <div className='flex flex-row justify-end'>
                <AddLanguage refresh = {setRefresh}/>
                </div>
            
            </div>
            <div className="">
                <DataTablev3
                    columns={columns}
                    previousPage={languages?.nextPage ?? 0}
                    nextPage={languages?.nextPage ?? 0}
                    currentPage={1}
                    data={languages?.list ?? []}
                    total={languages?.total ?? 0}
                    lastPage={languages?.lastPage ?? 0}
                    isLoading={loadingLanguages}
                />
                <DeleteUserLanguages
                    refresh={setRefresh}
                    selected={selected}
                    open={open}
                    setOpen={setOpen}
                    title={'Delete Experience'}
                />
            </div>
        </div>
    );
}
