import { FunnelIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { ModFilter, UserFilterPayload, UserType } from '@/core/types/auth';
import { PaginationType } from '@/core/types/shared';
import Button from '@/components/button';
import Modal from '@/components/modal';
import { Form } from '@/components/form';
import { InputField, SelectField } from '@/components/input';
import DataTableV2, { TableColumnV2 } from '@/components/datatable/DataTable';
import { NonEligibleApplications, useGetEvaluations } from '@/core/hooks/evaluators';
import { countries } from 'countries-list';
import { EvaluationType } from '@/core/types/evaluation';
import IconEye from '@/components/Icon/IconEye';
import { More } from '../application_decisions/more';
import { userInfo } from 'os';
import { useAppSelector } from '@/store';


export default function NonEligible() {
    const location = useLocation();
    const query = location.search;
    const navigate = useNavigate();
    const [refresh, setRefresh] = useState(false);

    const [filters, setFilters] = useState<UserFilterPayload>({
        from: '',
        to: '',
        country: '',
        gender: '',
        status: '',
    });
   

    const { loadingEvaluations, handleGetEvaluations, evaluations } =
        NonEligibleApplications();


    useEffect(() => {
        handleGetEvaluations(query);
    }, [query, refresh]);
    

    const columns: TableColumnV2<EvaluationType>[] = [
        {
            title: 'View',
            accessor: 'actions',
            render: row => (
                <p className="flex flex-row justify-between space-x-2">
                 
                    <button
                        onClick={() => {
                            navigate(
                                `/account/modulator/user_details/non-eligible/${row?.submittedApplication?.user.id}/${row?.id}/${row.submittedApplication.application.id}`
                            );
                        }}
                        className="btn btn-primary btn-sm "
                    >
                        <IconEye />
                    </button>
                </p>
            ),
        },
        {
            title: ' Applicant Code',
            accessor: 'submittedApplication.applicant_code',
            render: row => (
                <p>{row?.submittedApplication?.applicant_code || '-'} </p>
            ),
        },
        {
            title: 'Applicant Name',
            accessor: 'submittedApplication.user.firstName',
            render: row => (
                <Link
                    to={`/account/modulator/user_details/non-eligible/${row?.submittedApplication?.user.id}/${row?.id}/${row.submittedApplication.application.id}`}
                    className='hover:underline'
                >
                    {row?.submittedApplication?.user?.firstName || '-'}{' '}
                    {row?.submittedApplication?.user?.lastName || '-'}
                </Link>
            ),
        },
        {
            title: 'Gender',
            accessor: 'row.submittedApplication?.user?.personalInformation[0]?.gender',
            render: row => (
                <p className='capitalize'>
                    {row.submittedApplication?.user?.personalInformation[0]?.gender}
                </p>
            ),
        },
        {
            title: 'Country',
            accessor: 'row.submittedApplication?.user?.personalInformation[0]?.Nationality',
            render: row => (
                <p>
                    {row.submittedApplication?.user?.personalInformation[0]?.Nationality}
                </p>
            ),
        },
        {
            title: 'Highest Degree',
            accessor: 'levelOfStudy',
            render: row => (
              <p>
                {row?.submittedApplication.user.academicHistories.find(history => history.highest_qualification)?.levelOfStudy?.toUpperCase() || '-'}
              </p>
            ),
          }, 
        
        {
            title: 'Evaluator One decision',
            accessor: 'evaluator_one_marks',
            render: row => <p>{row?.evaluator_one_marks?.toUpperCase() || '-'}</p>,
        },

        {
            title: 'Evaluator Two decision',
            accessor: 'evaluator_two_marks',
            render: row => <p>{row?.evaluator_two_marks?.toUpperCase() || '-'}</p>,
        },
        {
            title: 'Evaluator Three decision',
            accessor: 'evaluator_three_marks',
            render: row => (
                <>
                    {row?.evaluatorThree !==null ? (
                        <p className='text-black'>{row?.evaluator_three_marks ?? '-'}</p>
                    ) : (
                        <p>No evaluator 3</p>
                    )}
                </>
            ),
        },
        {
            title: 'Evaluator One Name',
            accessor: 'evaluatorOne.lastName',
            render: row => (
                <p className="capitalize">
                    {(row?.evaluatorOne?.lastName || '-') +
                        ' ' +
                        (row?.evaluatorOne?.firstName || '-')}
                </p>
            ),
        },
       
       
        {
            title: 'Evaluator Two Name',
            accessor: 'evaluatorTwo.lastName',
            render: row => (
                <p className="capitalize">
                    {(row?.evaluatorTwo?.lastName || '-') +
                        ' ' +
                        (row?.evaluatorTwo?.firstName || '-')}
                </p>
            ),
        },
        {
            title: 'Evaluator Three Name',
            accessor: 'evaluatorThree.lastName',
            render: row => (
                <>
                    {row?.evaluatorThree !== null ? (
                        <p className="capitalize">
                            {(row.evaluatorThree?.lastName || '-') +
                                ' ' +
                                (row.evaluatorThree?.firstName || '-')}
                        </p>
                    ) : (
                        <p>No evaluator 3</p>
                    )}
                </>
            )
        },
        {
            title: 'Academic Manager Name',
            accessor: 'academicManager.lastName',
            render: row => (
                <>
                    {row?.academicManager !== null ? (
                        <p className="capitalize">
                            {(row.academicManager?.lastName || '-') +
                                ' ' +
                                (row.academicManager?.firstName || '-')}
                        </p>
                    ) : (
                        <p>No Academic Manager  </p>
                    )}
                </>
            )
        }
,        
        {
            title: 'Application Name',
            accessor: 'submittedApplication.application.title',
            render: row => (
                <p>{row.submittedApplication.application.title || '-'}</p>
            ),
        },
        {
            title: 'Evaluation status',
            accessor: 'status',
            render: row => {
                let badgeColor;
                switch (row?.status) {
                    case 'approved':
                        badgeColor = 'success';
                        break;
                    case 'reject':
                        badgeColor = 'danger';
                        break;
                    case 'assigned':
                        badgeColor = 'blue';
                        break;
                    case 'pending':
                        badgeColor = 'warning';
                        break;
                    default:
                        badgeColor = 'gray';
                }
                return (
                    <span className={` capitalize badge bg-${badgeColor}`}>
                        {row.status}
                    </span>
                );
            },
        },
        {
            title: 'Action',
            accessor: 'actions',
            render: row => (
                <p className="flex flex-row justify-between space-x-2">
                    <More evaluation={row} />
                    
                </p>
            ),
        },
    ];

    const { userAccount: user }: any = useAppSelector(store => store.user);
    return (
        <>
            <div className="my-2 ">
                <ol className="flex text-primary font-semibold dark:text-white-dark">
                    <li className="bg-[#ebedf2] ltr:rounded-l-md rtl:rounded-r-md dark:bg-[#1b2e4b]">
                        <Link
                            to={`/account/${user?.role}`}
                            className="p-1.5 ltr:pl-3 rtl:pr-3 ltr:pr-2 rtl:pl-2 relative  h-full flex items-center before:absolute ltr:before:-right-[15px] rtl:before:-left-[15px] rtl:before:rotate-180 before:inset-y-0 before:m-auto before:w-0 before:h-0 before:border-[16px] before:border-l-[15px] before:border-r-0 before:border-t-transparent before:border-b-transparent before:border-l-[#ebedf2] before:z-[1] dark:before:border-l-[#1b2e4b] hover:text-primary/70 dark:hover:text-white-dark/70"
                        >
                            Home
                        </Link>
                    </li>
                    <li className="bg-[#ebedf2] dark:bg-[#1b2e4b]">
                        <button className="bg-primary text-white-light p-1.5 ltr:pl-6 rtl:pr-6 ltr:pr-2 rtl:pl-2 relative  h-full flex items-center before:absolute ltr:before:-right-[15px] rtl:before:-left-[15px] rtl:before:rotate-180 before:inset-y-0 before:m-auto before:w-0 before:h-0 before:border-[16px] before:border-l-[15px] before:border-r-0 before:border-t-transparent before:border-b-transparent before:border-l-primary before:z-[1]">
                            Evaluations
                        </button>
                    </li>
                </ol>
            </div>

            <div className="my-4 ">
                <p className="text-lg font-bold "> Non Eligible Applications </p>
            </div>
            <div className="">
                <DataTableV2
                tableName='Evaluation Decisions'
                    columns={columns}
                    previousPage={evaluations?.previousPage ?? 0}
                    nextPage={evaluations?.nextPage ?? 0}
                    currentPage={evaluations?.currentPage ?? 0}
                    data={evaluations?.list ?? []}
                    total={evaluations?.total ?? 0}
                    lastPage={evaluations?.lastPage ?? 0}
                    isLoading={loadingEvaluations}
                    header={
                        <div className="w-full flex my-3 justify-end items-center">
                            <Filter
                                filters={filters}
                                data={evaluations}
                                onFilter={(payload: ModFilter) => {
                                    setFilters(payload);
                                }}
                            />
                        </div>
                    }
                />
            </div>
        </>
    );
}

type FilterProps = {
    filters: UserFilterPayload;
    onFilter: (...args: any) => void;
    data: PaginationType<UserType> | null;
};

function Filter(props: FilterProps) {
    const { onFilter, filters, data } = props;
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const schema = z.object({
        from: z.string().optional(),
        to: z.string().optional(),
        country: z.string().optional(),
        gender: z.string().optional(),
    });
    const defaultFilters: UserFilterPayload = {
        from: '',
        to: '',
        country: '',
        gender: '',
        status: '',
    };

    const handleOnSubmit = (payload: any) => {
        const searchParams = new URLSearchParams(location.search);
    
        const { from, to, country, gender } = payload;

        if (from) searchParams.set('from', from);
        if (to) searchParams.set('to', to);
        if (country) searchParams.set('country', country);
        if (gender) searchParams.set('gender', gender);
      
        const newSearch = searchParams.toString();
        onFilter(payload);
        navigate(`${location.pathname}?${newSearch}`);

        setOpen(false);
    };

    const resetFilter = () => {
        onFilter(defaultFilters);
        navigate(`${location.pathname}`);
        setOpen(false);
    };

    const countryOptions = Object.values(countries).map(country => ({
        value: country.name,
        text: country.name,
    }));

    return (
        <>
            <div className="w-full justify-end flex flex-wrap">
                <div className="flex flex-row gap-3">
                    <Button
                        title="Filter results"
                        onClick={() => setOpen(true)}
                    >
                        <FunnelIcon className="w-4" /> Filter
                    </Button>
                </div>
            </div>
            <Modal show={open} setShow={setOpen} title={'Filter'}>
                <Form<UserFilterPayload, typeof schema>
                    onSubmit={handleOnSubmit}
                    schema={schema}
                    options={{ defaultValues: filters }}
                >
                    {({ register, formState }) => (
                        <div className="px-3">
                            <div className="flex flex-col justify-between gap-4 ">
                                <InputField
                                    type="date"
                                    error={formState.errors.from}
                                    registration={register('from')}
                                    className="h-12"
                                    label="Start Date"
                                />

                                <InputField
                                    type="date"
                                    error={formState.errors.to}
                                    registration={register('to')}
                                    className="h-12"
                                    label="End Date"
                                />

                                <SelectField
                                    placeholder="Select Country"
                                    label="Country"
                                    registration={register('country')}
                                    options={countryOptions}
                                />

                                <SelectField
                                    placeholder="Select Gender"
                                    label="Gender"
                                    registration={register('gender')}
                                    options={[
                                        { value: 'male', text: 'Male' },
                                        { value: 'female', text: 'Female' },
                                    ]}
                                />

                            </div>
                            <div className="py-4 flex flex-row justify-between">
                                <Button
                                    onClick={resetFilter}
                                    background="bg-gray-500"
                                >
                                    Reset
                                </Button>
                                <Button>Filter</Button>
                            </div>
                        </div>
                    )}
                </Form>
            </Modal>
        </>
    );
}
