import { useEffect, useState } from 'react';
import DataTableV2, { TableColumnV2 } from '@/components/datatable/DataTable';
import { useLocation } from 'react-router-dom';
import formatDateToLongForm from '@/core/utils/DateFormattter';
import { TrashIcon } from '@heroicons/react/24/outline';
import { useAppSelector } from '@/store';
import { GetSupervisors } from '@/core/hooks/supervisor';
import { SupervisorType } from '@/core/types/supervisor';



export default function AdminViewSupervisorsInfo() {
    const [showForm, setShowForm] = useState(false);
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState<SupervisorType | null>(null);
    const [refresh, setRefresh] = useState(false);
    const location = useLocation();
    const query = location.search;
    const { userAccount: user }: any = useAppSelector(store => store.user);
      
    const { loadingSupervisors, handleGetSupervisors, supervisors } =
        GetSupervisors();

    const toggleFormVisibility = () => {
        setShowForm(!showForm);
    };

    const closeForm = () => {
        setShowForm(false);
    };

    useEffect(() => {
        handleGetSupervisors(query);
    }, [query, refresh]);
    const columns: TableColumnV2<SupervisorType>[] = [
        {
            title: 'Names',
            accessor: 'name',
            render: row => <p className="capitalize">{row?.name}</p>,
        },

        {
            title: 'Title',
            accessor: 'tittle',
            render: row => (
                <p className="capitalize whitespace-wrap">{row?.tittle}</p>
            ),
        },
        {
            title: 'Institution',
            accessor: 'institution',
            render: row => (
                <p className="capitalize whitespace-wrap">{row?.institution}</p>
            ),
        },
        {
            title: 'Country',
            accessor: 'country',
            render: row => (
                <p className="capitalize whitespace-wrap">{row?.country}</p>
            ),
        },
        {
            title: 'Email',
            accessor: 'email',
            render: row => (
                <p className="capitalize whitespace-wrap">{row?.email}</p>
            ),
        },
       
        {
            title: 'PhoneNumber',
            accessor: 'phoneNumber',
            render: row => (
                <p className="capitalize whitespace-wrap">{row?.phoneNumber}</p>
            ),
        },

        {
            title: 'Experience(years)',
            accessor: 'experience_years',
            render: row => (
                <p className="capitalize whitespace-wrap">{row?.experience_years}</p>
            ),
        },
        {
            title: 'Topic ',
            accessor: 'topic.title',
            render: row => (
                <p>
                  {row?.suggestedTopic?.title}
                </p>
            ),
        },
       

        {
            title: 'Date Created  ',
            accessor: 'created_at',
            render: row => <p>{formatDateToLongForm(row?.created_at)}</p>,
        },

     
    ];

    return (
        <div className="panel py-4 ">
          
            <div className="">
                <DataTableV2
                tableName='Supervisors'
                    columns={columns}
                    previousPage={supervisors?.previousPage ?? 0}
                    nextPage={supervisors?.nextPage ?? 0}
                    currentPage={supervisors?.currentPage ?? 0}
                    data={supervisors?.list ?? []}
                    total={supervisors?.total ?? 0}
                    lastPage={supervisors?.lastPage ?? 0}
                    isLoading={loadingSupervisors}
                />
           
            </div>
        </div>
    );
}
