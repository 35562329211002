import React, { useEffect, useState } from 'react';
import DataTableV2, { TableColumnV2 } from '@/components/datatable/DataTable';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import formatDateToLongForm from '@/core/utils/DateFormattter';
import { useAppSelector } from '@/store';
import { MyApplication, SubmitApplication } from '@/core/hooks/application';
import { ApplicationType } from '@/core/types/application';
import DataTablev3 from '@/components/datatable/Datatable2';
import Modal from '@/components/modal';
import Button from '@/components/button';

export default function MyApplications() {
    const [showForm, setShowForm] = useState(false);
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState<ApplicationType | any>(null);
    const [refresh, setRefresh] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    const query = location.search;

    const [confirmationOpen, setConfirmationOpen] = useState(false); // Add this state
    const { loadingSubmit, submitApplication } = SubmitApplication();
    const handleApply = (application_id: any) => {
        setConfirmationOpen(true);
       
     
    };

    const { userAccount: user }: any = useAppSelector(store => store.user);
    const { loadingApplications, handleGetApplications, applications } =
        MyApplication();

    const toggleFormVisibility = () => {
        setShowForm(!showForm);
    };

    const closeForm = () => {
        setShowForm(false);
    };

    useEffect(() => {
        handleGetApplications(query);
    }, [query, refresh]);

    const columns: TableColumnV2<ApplicationType>[] = [
        {
            title: 'Application',
            accessor: 'title',
            render: row => <p className="capitalize">{row?.title}</p>,
        },
        {
            title: 'Description',
            accessor: 'description',
            render: row => (
                <p className="capitalize whitespace-wrap">
                    {row?.description?.slice(0, 50)}
                    {row?.description && row?.description.length > 50 && '...'}
                </p>
            ),
        },
        {
            title: 'Status',
            accessor: 'status',
            render: row => (
                <p>
                    {row?.status === '1' || row?.status === 'active' ? (
                        <span className="badge bg-success">Active</span>
                    ) : (
                        <span className="badge bg-danger">Inactive</span>
                    )}
                </p>
            ),
        },
        {
            title: 'Date Created  ',
            accessor: 'created_at',
            render: row => <p>{formatDateToLongForm(row.created_at)}</p>,
        },

        {
            title: 'Actions',
            accessor: 'actions',
            render: row => (
                <p className=' flex flex-row gap-2 '>
                    <button
                        onClick={() => {
                    
                            navigate(`/account/applicant/apply/${row.id}`);
                        }}
                        className="btn btn-primary  "
                    >
                        View
                    </button>
                    <button
                       onClick={() => {
                        setSelected(row);
                        setOpen(true);
                    }}
                        className="btn btn-primary  "
                    >
                        Submit
                    </button>
                </p>
            ),
        },
    ];

    const application = applications?.[0]?.applications || [];

    return (
        <>
            <div className=" my-2 ">
                <ol className="flex text-primary font-semibold dark:text-white-dark">
                    <li className="bg-[#ebedf2] ltr:rounded-l-md rtl:rounded-r-md dark:bg-[#1b2e4b]">
                        <Link
                            to="/account/applicant"
                            className="p-1.5 ltr:pl-3 rtl:pr-3 ltr:pr-2 rtl:pl-2 relative  h-full flex items-center before:absolute ltr:before:-right-[15px] rtl:before:-left-[15px] rtl:before:rotate-180 before:inset-y-0 before:m-auto before:w-0 before:h-0 before:border-[16px] before:border-l-[15px] before:border-r-0 before:border-t-transparent before:border-b-transparent before:border-l-[#ebedf2] before:z-[1] dark:before:border-l-[#1b2e4b] hover:text-primary/70 dark:hover:text-white-dark/70"
                        >
                            Home
                        </Link>
                    </li>

                    <li className="bg-[#ebedf2] dark:bg-[#1b2e4b]">
                        <button className="bg-primary text-white-light p-1.5 ltr:pl-6 rtl:pr-6 ltr:pr-2 rtl:pl-2 relative  h-full flex items-center before:absolute ltr:before:-right-[15px] rtl:before:-left-[15px] rtl:before:rotate-180 before:inset-y-0 before:m-auto before:w-0 before:h-0 before:border-[16px] before:border-l-[15px] before:border-r-0 before:border-t-transparent before:border-b-transparent before:border-l-primary before:z-[1]">
                            My Applications
                        </button>
                    </li>
                </ol>
            </div>
            <div className="panel py-4 ">
                <div className="">
                    {user.role === 'admin' && (
                        <DataTableV2
                        tableName=''
                            columns={columns}
                            previousPage={0}
                            nextPage={0}
                            currentPage={1}
                            data={application}
                            total={application?.length ?? 0}
                            lastPage={1}
                            isLoading={loadingApplications}
                        />
                    )}
                    {user.role === 'applicant' && (
                        <DataTablev3
                            columns={columns}
                            previousPage={0}
                            nextPage={0}
                            currentPage={1}
                            data={application}
                            total={application?.length ?? 0}
                            lastPage={applications?.lastPage ?? 0}
                            isLoading={loadingApplications}
                        />
                    )}
                </div>
            </div>
            <ConfirmationModal
                open={open}
                setOpen={setOpen}
                selected={selected}
                onConfirm={() => {
                   
                    submitApplication(selected.id);
                    setConfirmationOpen(false);
                }}
            />
        </>
    );
}

const ConfirmationModal = ({ open, setOpen, onConfirm }: any) => {
    return (
        <Modal show={open} setShow={setOpen} title="Confirm Submission">
            <p>By submitting this application, I certify that all information provided is accurate to the best of my knowledge. I also confirm that I have submitted ONLY ONE application for this call</p>
            <div className="flex gap-3  justify-end">
                <Button onClick={() => setOpen(false)} background="bg-gray-500">
                    Cancel
                </Button>
                <Button onClick={onConfirm}>Submit</Button>
            </div>
        </Modal>
    );
};
