import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { SelectField } from '@/components/input';
import { useCreateSocioEconomic, useUpdateSocioEconomic, useFetchSocioEconomicByUser } from '@/core/hooks/social';
import { useAppSelector } from '@/store';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';


const socioEconomicSchema = z
  .object({
    parentsStatus: z.string().nonempty('Status of parents is required'),
    mainBreadwinner: z.string().nonempty('Main breadwinner is required'),
    householdDependents: z.string().nonempty('Household dependents are required'),
    houseOwnership: z.string().nonempty('House ownership is required'),
    landOwnership: z.string().nonempty('Land ownership is required'),
    residenceType: z.string().nonempty('Residence type is required'),
    roofingMaterial: z.string().nonempty('Roofing material is required'),
    householdIncome: z.string().nonempty('Household income is required'),
    governmentSupport: z.string().nonempty('Government support is required'),
    cookingEnergy: z.string().nonempty('Cooking energy is required'),
    stoppedSchool: z.string().nonempty('This field is required'),
    schoolDropoutReason: z.string().optional(),
  })
  .superRefine((data, ctx) => {
    if (data.stoppedSchool === 'yes' && !data.schoolDropoutReason) {
      ctx.addIssue({
        code: 'custom',
        path: ['schoolDropoutReason'],
        message: 'Please provide a reason for stopping school if applicable',
      });
    }
  });

type FormData = z.infer<typeof socioEconomicSchema>;

export default function SocioEconomic() {
  const { userAccount: userData }: any = useAppSelector(store => store.user);
  const { handleFetchByUser, loading: fetchingData, data } = useFetchSocioEconomicByUser();
  const { loading: creatingData, handleCreate } = useCreateSocioEconomic();
  const { loading: updatingData, handleUpdate } = useUpdateSocioEconomic();

  const [isUpdate, setIsUpdate] = useState(false);
  const [existingDataId, setExistingDataId] = useState<string | null>(null);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<FormData>({
    resolver: zodResolver(socioEconomicSchema),
    defaultValues: {
      parentsStatus: '',
      mainBreadwinner: '',
      householdDependents: '',
      houseOwnership: '',
      landOwnership: '',
      residenceType: '',
      roofingMaterial: '',
      householdIncome: '',
      governmentSupport: '',
      cookingEnergy: '',
      stoppedSchool: 'no',
      schoolDropoutReason: '',
    },
  });

  // Fetch socio-economic data when component mounts
  useEffect(() => {
    if (userData?.id) {
      handleFetchByUser(userData.id);
    }
  }, [userData]);

  // Populate form if existing data is found
  useEffect(() => {
    if (data && data.length > 0) {
      const socioData = data[0]; // Assuming the first record is used
      setIsUpdate(true);
      setExistingDataId(socioData.id);

      for (const [key, value] of Object.entries(socioData)) {
        setValue(key as keyof FormData, value?.toString());
      }
      setValue('stoppedSchool', socioData.stoppedSchool ? 'yes' : 'no');
    } else {
      setIsUpdate(false);
    }
  }, [data, setValue]);

  const onSubmit = async (formData: FormData) => {
    const numericPayload = {
      ...Object.fromEntries(
        Object.entries(formData).map(([key, value]) => {
          if (key === 'stoppedSchool') return [key, value === 'yes'];
          return [key, Number(value)];
        })
      ),
    };

    const payload = {
      parentsStatus: numericPayload.parentsStatus,
      mainBreadwinner: numericPayload.mainBreadwinner,
      householdDependents: numericPayload.householdDependents,
      houseOwnership: numericPayload.houseOwnership,
      landOwnership: numericPayload.landOwnership,
      residenceType: numericPayload.residenceType,
      roofingMaterial: numericPayload.roofingMaterial,
      householdIncome: numericPayload.householdIncome,
      governmentSupport: numericPayload.governmentSupport,
      cookingEnergy: numericPayload.cookingEnergy,
      schoolDropoutReason: numericPayload.schoolDropoutReason,
    };

    try {
      if (isUpdate && existingDataId) {
        await handleUpdate(existingDataId, payload);
      } else {
        await handleCreate(payload as any);
      }
    } catch (error: any) {
      const errorMsg = error.response?.data?.message || 'Error submitting form. Please check your input and try again.';
      toast.error(errorMsg);
    }
  };

  if (fetchingData) {
    return <p className="text-center">Loading socio-economic data...</p>;
  }

  return (
    <div className="panel">
      <h2 className="my-6 text-lg font-bold">{isUpdate ? 'Update Socio-Economic Information' : 'Fill Socio-Economic Information'}</h2>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        {/* Questions */}
        <Field label="1. Status of parents" name="parentsStatus" error={errors.parentsStatus} register={register} options={[
          { text: 'Both parents deceased', value: '4' },
          { text: 'One parent deceased', value: '2' },
          { text: 'Both parents alive', value: '1' },
        ]} />

        <Field label="2. Who is the main breadwinner for your household?" name="mainBreadwinner" error={errors.mainBreadwinner} register={register} options={[
          { text: 'Myself', value: '4' },
          { text: 'Other relative/my sibling', value: '3' },
          { text: 'One parent', value: '2' },
          { text: 'Two parents', value: '1' },
        ]} />

        <Field label="3. How many people are dependent on your household income?" name="householdDependents" error={errors.householdDependents} register={register} options={[
          { text: 'More than 5', value: '4' },
          { text: '3 to 5', value: '3' },
          { text: '1 - 2', value: '2' },
          { text: '0', value: '1' },
        ]} />

        <Field label="4. Who owns the house where your family lives?" name="houseOwnership" error={errors.houseOwnership} register={register} options={[
          { text: 'Government subsidized house', value: '3' },
          { text: 'Rented', value: '2' },
          { text: 'Family owned', value: '1' },
        ]} />

        <Field label="5. Does your family own a piece of land?" name="landOwnership" error={errors.landOwnership} register={register} options={[
          { text: 'No', value: '2' },
          { text: 'Yes', value: '1' },
        ]} />

        <Field label="6. In which locality does your family live?" name="residenceType" error={errors.residenceType} register={register} options={[
          { text: 'Urban/Peri-urban informal settlement', value: '4' },
          { text: 'Rural', value: '3' },
          { text: 'Peri-urban', value: '2' },
          { text: 'Urban', value: '1' },
        ]} />

        <Field label="7. What is the type of roofing material for the house you live in?" name="roofingMaterial" error={errors.roofingMaterial} register={register} options={[
          { text: 'Grass/other improvised materials', value: '4' },
          { text: 'Iron sheet/tin', value: '2' },
          { text: 'Cement/concrete', value: '1' },
        ]} />

        <Field label="8. Under which of the following categories does your household total monthly income fall?" name="householdIncome" error={errors.householdIncome} register={register} options={[
          { text: '0 – 500 USD', value: '5' },
          { text: '501-1000 USD', value: '4' },
          { text: '1001 - 1500 USD', value: '3' },
          { text: '1501 - 2000 USD', value: '2' },
          { text: '2001 - above', value: '1' },
        ]} />

        <Field label="9. Does your household rely on any government support or social support?" name="governmentSupport" error={errors.governmentSupport} register={register} options={[
          { text: 'Yes', value: '2' },
          { text: 'No', value: '0' },
        ]} />

        <Field label="10. What is the main source of energy for cooking in your household?" name="cookingEnergy" error={errors.cookingEnergy} register={register} options={[
          { text: 'Firewood', value: '4' },
          { text: 'Charcoal/paraffin', value: '3' },
          { text: 'Liquefied Petroleum Gas (LPG)', value: '2' },
          { text: 'Electricity', value: '1' },
        ]} />

        <Field label="11. Have you stopped attending school at any level in your life?" name="stoppedSchool" error={errors.stoppedSchool} register={register} options={[
          { text: 'No', value: 'no' },
          { text: 'Yes', value: 'yes' },
        ]} />

        {getValues('stoppedSchool') === 'yes' && (
          <Field label="12. What was the reason for stopping school?" name="schoolDropoutReason" error={errors.schoolDropoutReason} register={register} options={[
            { text: 'Lack of fees/uniform/other school supplies', value: '3' },
            { text: 'Conflict (tribal, resource based)', value: '2' },
            { text: 'My family migrated', value: '1' },
            { text: 'Poor grades', value: '0' },
            { text: 'Sickness', value: '0' },
          ]} />
        )}

        <button type="submit" className="btn btn-primary" disabled={creatingData || updatingData}>
          {isUpdate ? 'Update' : 'Submit'}
        </button>
      </form>
    </div>
  );
}

// Reusable Field Component
const Field = ({ label, name, error, register, options }: any) => (
  <>
    <h3 className="font-semibold">{label}</h3>
    <SelectField
      placeholder="Select an option"
      error={error?.message}
      options={options}
      registration={register(name)}
    />
  </>
);
