import { useEffect, useState } from 'react';
import Button from '@/components/button';
import { Form } from '@/components/form';
import { InputField, SelectField } from '@/components/input';
import {
    GetMyAddress,
    getAddresses,
    useCreateAddress,
} from '@/core/hooks/address';
import { AddressType } from '@/core/types/address';
import { z } from 'zod';
import IconX from '@/components/Icon/IconX';
import DataTableV2, { TableColumnV2 } from '@/components/datatable/DataTable';
import { useLocation } from 'react-router-dom';
import formatDateToLongForm from '@/core/utils/DateFormattter';
import DeleteUserModal from '../users/modals/delete_modal';
import { TrashIcon } from '@heroicons/react/24/outline';
import { useAppSelector } from '@/store';
import { AddExperience } from '../experience/modal/Add_Experience';
import { GetApplications } from '@/core/hooks/application';
import { ApplicationType } from '@/core/types/application';
import { GetCategories } from '@/core/hooks/category';
import { CategoryType } from '@/core/types/category';
import { active } from 'sortablejs';
import { AddCategory } from './modal/add_category';
import DeleteCategory from './modal/deleting_topic';
import { UpdateCategory } from './modal/update_category';
import { AddSubCategories } from './modal/add_sub_category';

export default function Categories() {
    const [showForm, setShowForm] = useState(false);
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState<CategoryType | null>(null);
    const [refresh, setRefresh] = useState(false);
    const location = useLocation();
    const query = location.search;
    const { userAccount: user }: any = useAppSelector(store => store.user);
  
    const { loadingCategories, handleGetCategories, categories } =
        GetCategories();

    const toggleFormVisibility = () => {
        setShowForm(!showForm);
    };

    const closeForm = () => {
        setShowForm(false);
    };

    useEffect(() => {
        handleGetCategories(query);
    }, [query, refresh]);
    const columns: TableColumnV2<CategoryType>[] = [
        {
            title: 'Name',
            accessor: 'name',
            render: row => <p className="capitalize">{row?.name}</p>,
        },

        {
            title: 'Sub category',
            accessor: 'subcategory',
            render: row => (
                <p className="capitalize whitespace-wrap">
                    {row?.sub_category?.name || '-'}
                </p>
            ),
        },

        {
            title: 'Required Skills',
            accessor: '',
            render: row => (
                <div>
                    {' '}
                    {row.skills_required.map(skill => (
                        <p>{skill.name}</p>
                    ))}
                </div>
            ),
        },
        {
            title: 'Creator Name',
            accessor: 'user.firstName',
            render: row => (
                <p className="capitalize whitespace-wrap">
                    {row.user.firstName}
                    {row.user.lastName}
                </p>
            ),
        },

        {
            title: 'Creator email',
            accessor: 'user.email',
            render: row => <p className=" whitespace-wrap">{row.user.email}</p>,
        },

        {
            title: 'Date Created  ',
            accessor: 'created_at',
            render: row => <p>{formatDateToLongForm(row?.created_at)}</p>,
        },

        {
            title: 'Actions',
            accessor: 'actions',
            render: row => (
                <div className="flex gap-2 justify-center">
                    <button
                        onClick={() => {
                            setSelected(row);
                            setOpen(true);
                        }}
                        className="bg-red-700 text-gray-200 p-1 rounded"
                    >
                        <TrashIcon className="w-4" />
                    </button>

                    <UpdateCategory data={row} refresh={setRefresh} />
                    <AddSubCategories data={row} refresh={setRefresh} />
                </div>
            ),
        },
    ];

    return (
        <div className="panel py-4 ">
            <div>
                <div className="flex flex-row justify-end">
                    <AddCategory refresh={setRefresh} />
                </div>
            </div>
            <div className="">
                <DataTableV2
                tableName=' cCategories'
                    columns={columns}
                    previousPage={categories?.previousPage ?? 0}
                    nextPage={categories?.nextPage ?? 0}
                    currentPage={categories?.currentPage ?? 0}
                    data={categories?.list ?? []}
                    total={categories?.total ?? 0}
                    lastPage={categories?.lastPage ?? 0}
                    isLoading={loadingCategories}
                />
                <DeleteCategory
                    refresh={setRefresh}
                    selected={selected}
                    open={open}
                    setOpen={setOpen}
                    title={'Delete Categoryj'}
                />
            </div>
        </div>
    );
}
