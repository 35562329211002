// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.swiper .swiper-button-disabled {
    cursor: not-allowed;
    opacity: 0.6;
}

.rtl .swiper {
    direction: rtl;
}

.swiper-button-next {
    color: #4361ee;
}

#slider3 .swiper-wrapper,
#slider4 .swiper-wrapper {
    height: 320px !important;
}

#slider3 .swiper-wrapper .swiper-slide img,
#slider4 .swiper-wrapper .swiper-slide img {
    height: 100%;
    object-fit: cover;
}

#slider3 .swiper-pagination .swiper-pagination-bullet {
    border-radius: 6px;
    width: 4px;
    height: 20px;
}

@media only screen and (max-width: 1200px) {
    #slider3 .swiper-wrapper,
    #slider4 .swiper-wrapper {
        height: 300px !important;
    }
}

@media only screen and (max-width: 600px) {
    #slider3 .swiper-wrapper,
    #slider4 .swiper-wrapper {
        height: 180px !important;
    }
}

#slider3 .swiper-pagination .swiper-pagination-bullet:hover {
    background-color: #fff;
}

#slider3 .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #4361ee;
}

#slider4 .swiper-pagination {
    color: #fff;
}
#slider5 .swiper-wrapper {
    height: auto;
}
#slider5 .swiper-pagination {
    position: relative;
    margin-top: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/swiper.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;;IAEI,wBAAwB;AAC5B;;AAEA;;IAEI,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,YAAY;AAChB;;AAEA;IACI;;QAEI,wBAAwB;IAC5B;AACJ;;AAEA;IACI;;QAEI,wBAAwB;IAC5B;AACJ;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,WAAW;AACf;AACA;IACI,YAAY;AAChB;AACA;IACI,kBAAkB;IAClB,gBAAgB;AACpB","sourcesContent":[".swiper .swiper-button-disabled {\n    cursor: not-allowed;\n    opacity: 0.6;\n}\n\n.rtl .swiper {\n    direction: rtl;\n}\n\n.swiper-button-next {\n    color: #4361ee;\n}\n\n#slider3 .swiper-wrapper,\n#slider4 .swiper-wrapper {\n    height: 320px !important;\n}\n\n#slider3 .swiper-wrapper .swiper-slide img,\n#slider4 .swiper-wrapper .swiper-slide img {\n    height: 100%;\n    object-fit: cover;\n}\n\n#slider3 .swiper-pagination .swiper-pagination-bullet {\n    border-radius: 6px;\n    width: 4px;\n    height: 20px;\n}\n\n@media only screen and (max-width: 1200px) {\n    #slider3 .swiper-wrapper,\n    #slider4 .swiper-wrapper {\n        height: 300px !important;\n    }\n}\n\n@media only screen and (max-width: 600px) {\n    #slider3 .swiper-wrapper,\n    #slider4 .swiper-wrapper {\n        height: 180px !important;\n    }\n}\n\n#slider3 .swiper-pagination .swiper-pagination-bullet:hover {\n    background-color: #fff;\n}\n\n#slider3 .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {\n    background: #4361ee;\n}\n\n#slider4 .swiper-pagination {\n    color: #fff;\n}\n#slider5 .swiper-wrapper {\n    height: auto;\n}\n#slider5 .swiper-pagination {\n    position: relative;\n    margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
