import { useEffect, useState } from 'react';
import DataTableV2, { TableColumnV2 } from '@/components/datatable/DataTable';
import { useLocation } from 'react-router-dom';
import formatDateToLongForm from '@/core/utils/DateFormattter';
import { TrashIcon } from '@heroicons/react/24/outline';
import { useAppSelector } from '@/store';
import { GetSupervisors } from '@/core/hooks/supervisor';
import { SupervisorType } from '@/core/types/supervisor';
import CreateSupervisor from './modal/create_supervisor';
import DeleteSupervisor from './modal/delete_supervisor';
import UpdateSupervisor from './modal/update_supervisor';
import DataTablev3 from '@/components/datatable/Datatable2';


export default function SupervisorsInfo() {
    const [showForm, setShowForm] = useState(false);
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState<SupervisorType | null>(null);
    const [refresh, setRefresh] = useState(false);
    const location = useLocation();
    const query = location.search;
    const { userAccount: user }: any = useAppSelector(store => store.user);
      
    const { loadingSupervisors, handleGetSupervisors, supervisors } =
        GetSupervisors();

    const toggleFormVisibility = () => {
        setShowForm(!showForm);
    };

    const closeForm = () => {
        setShowForm(false);
    };

    useEffect(() => {
        handleGetSupervisors(query);
    }, [query, refresh]);
    const columns: TableColumnV2<SupervisorType>[] = [
        {
            title: 'Names',
            accessor: 'name',
            render: row => <p className="capitalize">{row?.name}</p>,
        },

        {
            title: 'Title',
            accessor: 'tittle',
            render: row => (
                <p className="capitalize whitespace-wrap">{row?.tittle}</p>
            ),
        },
        {
            title: 'Institution',
            accessor: 'institution',
            render: row => (
                <p className="capitalize whitespace-wrap">{row?.institution}</p>
            ),
        },
        {
            title: 'Country',
            accessor: 'country',
            render: row => (
                <p className="capitalize whitespace-wrap">{row?.country}</p>
            ),
        },
        {
            title: 'Email',
            accessor: 'email',
            render: row => (
                <p className="capitalize whitespace-wrap">{row?.email}</p>
            ),
        },
       
        {
            title: 'PhoneNumber',
            accessor: 'phoneNumber',
            render: row => (
                <p className="capitalize whitespace-wrap">{row?.phoneNumber}</p>
            ),
        },

        {
            title: 'Experience(years)',
            accessor: 'experience_years',
            render: row => (
                <p className="capitalize whitespace-wrap">{row?.experience_years}</p>
            ),
        },
        {
            title: 'Topic ',
            accessor: 'topic.title',
            render: row => (
                <p>
                  {row?.suggestedTopic?.title}
                </p>
            ),
        },
       

        {
            title: 'Date Created  ',
            accessor: 'created_at',
            render: row => <p>{formatDateToLongForm(row?.created_at)}</p>,
        },

        {
            title: 'Actions',
            accessor: 'actions',
            render: row => (
                <div className="flex gap-2 justify-center">
                    <button
                        onClick={() => {
                            setSelected(row);
                            setOpen(true);
                        }}
                        className="bg-red-700 text-gray-200 p-1 rounded"
                    >
                        <TrashIcon className="w-4" />
                    </button>

                    
                     <UpdateSupervisor supervisor={row} refresh={setRefresh} />
                </div>
            ),
        },
    ];

    return (
        <div className="panel py-4 ">
            <div>
                <div className="flex flex-row justify-end">
                    <CreateSupervisor refresh={setRefresh} />
                </div>
            </div>
            <div className="">
                <DataTablev3
                    columns={columns}
                    previousPage={supervisors?.previousPage ?? 0}
                    nextPage={supervisors?.nextPage ?? 0}
                    currentPage={supervisors?.currentPage ?? 0}
                    data={supervisors?.list ?? []}
                    total={supervisors?.total ?? 0}
                    lastPage={supervisors?.lastPage ?? 0}
                    isLoading={loadingSupervisors}
                />
                <DeleteSupervisor
                    refresh={setRefresh}
                    selected={selected}
                    open={open}
                    setOpen={setOpen}
                    title={'Delete Supervisor'}
                />
            </div>
        </div>
    );
}
