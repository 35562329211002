import { useEffect, useState } from 'react';
import Button from '@/components/button';
import { Form } from '@/components/form';
import { InputField, SelectField } from '@/components/input';
import { z } from 'zod';
import DataTableV2, { TableColumnV2 } from '@/components/datatable/DataTable';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import formatDateToLongForm from '@/core/utils/DateFormattter';
import { FunnelIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useAppSelector } from '@/store';
import {
    ActivateApplication,
    GetApplications,
    GetSubmittedApplications,
    SubmitApplication,
    useApplyApplication,
} from '@/core/hooks/application';
import {
    ApplicationFilterPayload,
    ApplicationType,
} from '@/core/types/application';
import { UpdateApplication } from './modal/Update_Application';
import DeletApplications from './modal/Application_deleting';
import { More } from './modal/More';
import DataTablev3 from '@/components/datatable/Datatable2';
import { UserFilterPayload } from '@/core/types/auth';
import { PaginationType } from '@/core/types/shared';
import Modal from '@/components/modal';
import { useGetAllUsers } from '@/core/hooks/auth';
import { useDispatch } from 'react-redux';
import { setPageTitle } from '@/store/themeConfigSlice';
import { AssignAllApplications } from '@/core/hooks/evaluators';
import { AssignApplication } from './modal/asssgn_applications';
import IconEye from '@/components/Icon/IconEye';

export default function Applications() {
    const [showForm, setShowForm] = useState(false);
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState<ApplicationType | any>(null);
    const [refresh, setRefresh] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [confirmationOpen, setConfirmationOpen] = useState(false); // Add this state
    const { applyApplication, loadingApply } = useApplyApplication();
    const { handleActivateApplication, loadingActivate } =
        ActivateApplication();

    const {
        handleGetApplications: handlemy_application,
        applications: my_application,
    } = GetSubmittedApplications();

    const handleApply = (application_id: any) => {
        applyApplication(application_id);
    };

    const handldeActivate = async (application_id: any) => {
        await handleActivateApplication(application_id);
        setRefresh(true);
    };

    const [filters, setFilters] = useState<ApplicationFilterPayload>({
        from: '',
        to: '',
        userId: '',
    });
    const query = location.search;

    const updatePageSizeInQuery = (pageSize: 10): string => {
        const urlParams = new URLSearchParams(query);
        urlParams.set('pageSize', pageSize.toString());
        return '?' + urlParams.toString();
    };
    const { loadingSubmit, submitApplication } = SubmitApplication();

    const { userAccount: user }: any = useAppSelector(store => store.user);
    const { loadingApplications, handleGetApplications, applications } =
        GetApplications();

    const filteredApplications =
        user.role === 'applicant'
            ? applications?.list?.filter(
                  (application: { status: string }) =>
                      application?.status === '1' ||
                      application?.status === 'active'
              ) ?? []
            : applications?.list ?? [];

    const mySubmittedApplications = my_application?.list || [];


    const nonSubmittedApplications = filteredApplications.filter(
        (application: { id: string }) =>
            !mySubmittedApplications.some(
                (submittedApp: any) =>
                    submittedApp.application.id === application.id
            )
    );

    const toggleFormVisibility = () => {
        setShowForm(!showForm);
    };

    const closeForm = () => {
        setShowForm(false);
    };

    const PageSize = 10;
    useEffect(() => {
        handleGetApplications(query);
        handlemy_application();
    }, [query, refresh]);

    const columns: TableColumnV2<ApplicationType>[] = [
        {
            title: 'Application Code',
            accessor: 'code',
            render: row => <p className="capitalize">{row?.code || '-'}</p>,
        },
        {
            title: 'Application',
            accessor: 'title',
            render: row => <p className="capitalize">{row?.title}</p>,
        },
        {
            title: 'Description',
            accessor: 'description',
            render: row => (
                <p
                    className="capitalize whitespace-wrap"
                    dangerouslySetInnerHTML={{
                        __html: row?.description?.slice(0, 50),
                    }}
                ></p>
            ),
        },
        {
            title: 'Start Date',
            accessor: 'starting_date',
            render: row => <p>{formatDateToLongForm(row?.starting_date)}</p>,
        },
        {
            title: 'End Date',
            accessor: 'ending_date',
            render: row => <p>{formatDateToLongForm(row?.ending_date)}</p>,
        },

        {
            title: 'Status',
            accessor: 'status',
            render: row => (
                <p>
                    {row?.status === '1' || row?.status === 'active' ? (
                        <span className="badge bg-success">Active</span>
                    ) : (
                        <span className="badge bg-danger">Inactive</span>
                    )}
                </p>
            ),
        },
        {
            title: 'Date Created  ',
            accessor: 'created_at',
            render: row => <p>{formatDateToLongForm(row?.created_at)}</p>,
        },

        {
            title: 'Actions',
            accessor: 'actions',
            render: row => (
                <div className="flex gap-2 justify-center">
                    {user.role === 'applicant' && (
                        <button
                            onClick={() => {
                                handleApply(row.id);
                                navigate(`/account/applicant/apply/${row.id}`);
                            }}
                            className="btn btn-primary"
                        >
                            Open
                        </button>
                    )}
                    {user.role === 'admin' ? (
                        <>
                            <button
                                onClick={() => {
                                    handldeActivate(row.id);
                                }}
                                className="btn btn-primary btn-sm"
                            >
                                {row?.status === '1' ||
                                row?.status === 'active' ? (
                                    <span>Deactivate</span>
                                ) : (
                                    <span>Activate</span>
                                )}
                            </button>
                            <button
                                className="btn btn-primary btn-sm "
                                onClick={() => {
                                    handleApply(row.id);
                                    navigate(
                                        `/account/admin/application/application-details/${row.id}`
                                    );
                                }}
                            >
                                <IconEye />
                            </button>
                            <button
                                onClick={() => {
                                    setSelected(row);
                                    setOpen(true);
                                }}
                                className="btn btn-sm  btn-danger "
                            >
                                <TrashIcon className="w-4" />
                            </button>
                        </>
                    ) : (
                        <More application={row} refresh={setRefresh} />
                    )}
                </div>
            ),
        },
    ];

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setPageTitle('Applications'));
    }, [dispatch]);

    return (
        <>
            <div>
                <div className="my-2 ">
                    <ol className="flex text-primary font-semibold dark:text-white-dark">
                        <li className="bg-[#ebedf2] ltr:rounded-l-md rtl:rounded-r-md dark:bg-[#1b2e4b]">
                            <Link
                                to={`/account/${user?.role}`}
                                className="p-1.5 ltr:pl-3 rtl:pr-3 ltr:pr-2 rtl:pl-2 relative  h-full flex items-center before:absolute ltr:before:-right-[15px] rtl:before:-left-[15px] rtl:before:rotate-180 before:inset-y-0 before:m-auto before:w-0 before:h-0 before:border-[16px] before:border-l-[15px] before:border-r-0 before:border-t-transparent before:border-b-transparent before:border-l-[#ebedf2] before:z-[1] dark:before:border-l-[#1b2e4b] hover:text-primary/70 dark:hover:text-white-dark/70"
                            >
                                Home
                            </Link>
                        </li>
                        <li className="bg-[#ebedf2] dark:bg-[#1b2e4b]">
                            <button className="bg-primary text-white-light p-1.5 ltr:pl-6 rtl:pr-6 ltr:pr-2 rtl:pl-2 relative  h-full flex items-center before:absolute ltr:before:-right-[15px] rtl:before:-left-[15px] rtl:before:rotate-180 before:inset-y-0 before:m-auto before:w-0 before:h-0 before:border-[16px] before:border-l-[15px] before:border-r-0 before:border-t-transparent before:border-b-transparent before:border-l-primary before:z-[1]">
                                Applications
                            </button>
                        </li>
                    </ol>
                </div>

                <div>
                    <div className="my-4 ">
                        <p className="text-lg font-bold "> Applications </p>
                    </div>

                    <div></div>
                </div>
            </div>
            <div className="panel py-4 ">
                <div className="">
                    {user.role === 'applicant' ? (
                        <DataTablev3
                            columns={columns}
                            previousPage={applications?.previousPage ?? 0}
                            nextPage={applications?.nextPage ?? 0}
                            currentPage={applications?.currentPage ?? 0}
                            data={applications?.list ?? []}
                            total={nonSubmittedApplications?.total ?? 0}
                            lastPage={applications?.lastPage ?? 0}
                            isLoading={loadingApplications}
                        />
                    ) : (
                        <DataTableV2
                            tableName=" Applications"
                            columns={columns}
                            previousPage={0}
                            nextPage={0}
                            currentPage={1}
                            data={applications?.list ?? []}
                            total={applications?.total ?? 0}
                            lastPage={applications?.lastPage ?? 0}
                            isLoading={loadingApplications}
                            header={
                                <>
                                    <div className="w-full flex my-3  space-x-2 justify-end items-center">
                                        {user?.role === 'admin' && (
                                            <>
                                                <div>
                                                    <button
                                                        className="btn btn-primary "
                                                        onClick={() => {
                                                            navigate(
                                                                `/account/admin/application/create`
                                                            );
                                                        }}
                                                    >
                                                        Create Application
                                                    </button>
                                                </div>
                                                <div>
                                                    <AssignApplication />
                                                </div>
                                            </>
                                        )}
                                        <div>
                                            <Filter
                                                filters={filters}
                                                data={applications}
                                                onFilter={(
                                                    payload: ApplicationFilterPayload
                                                ) => {
                                                    setFilters(payload);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </>
                            }
                        />
                    )}

                    <DeletApplications
                        refresh={setRefresh}
                        selected={selected}
                        open={open}
                        setOpen={setOpen}
                        title={'Delete Applications'}
                    />
                    <ConfirmationModal
                        open={confirmationOpen}
                        setOpen={setConfirmationOpen}
                        onConfirm={() => {
                            submitApplication(selected);
                            setConfirmationOpen(false);
                        }}
                    />
                </div>
            </div>
        </>
    );
}

type FilterProps = {
    filters: ApplicationFilterPayload;
    onFilter: (...args: any) => void;
    data: PaginationType<ApplicationType> | null;
};

function Filter(props: FilterProps) {
    const { onFilter, filters, data } = props;
    const [open, setOpen] = useState(false);
    const [openPrint, setOpenPrint] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const schema = z.object({
        from: z.string().optional(),
        to: z.string().optional(),
        userId: z.string().optional(),
    });

    const defaultFilters: UserFilterPayload = {
        from: '',
        to: '',
    };
    const handleOnSubmit = (payload: any) => {
        const searchParams = new URLSearchParams(location.search);

        const { from, to, userId } = payload;

        if (from) searchParams.set('from', from);
        if (to) searchParams.set('to', to);
        if (userId) searchParams.set('userId', userId);

        const newSearch = searchParams.toString();
        onFilter(payload);
        navigate(`${location.pathname}?${newSearch}`);

        setOpen(false);
    };
    const resetFilter = () => {
        onFilter(defaultFilters);
        navigate(`${location.pathname}`);
        setOpen(false);
    };

    const query = location.search;
    const { loadingUsers, handleGetUsers, users } = useGetAllUsers();
    useEffect(() => {
        handleGetUsers(query);
    }, []);

    return (
        <>
            <div className="w-full justify-end flex flex-wrap">
                <div className="flex flex-row gap-3">
                    <Button
                        title="Filter results"
                        onClick={() => setOpen(true)}
                    >
                        <FunnelIcon className="w-4" /> Filter
                    </Button>
                </div>
            </div>
            <Modal show={open} setShow={setOpen} title={'Filter'}>
                <Form<ApplicationFilterPayload, typeof schema>
                    onSubmit={handleOnSubmit}
                    schema={schema}
                    options={{ defaultValues: filters }}
                >
                    {({ register, formState }) => (
                        <div className="px-3">
                            <div className="grid grid-cols-1">
                                <InputField
                                    type="date"
                                    error={formState.errors.from}
                                    registration={register('from')}
                                    className="h-12"
                                    label="Start Date"
                                />

                                <InputField
                                    type="date"
                                    error={formState.errors.to}
                                    registration={register('to')}
                                    className="h-12"
                                    label="End Date"
                                />
                                <SelectField
                                    placeholder="Select User"
                                    isLoading={loadingUsers}
                                    label="User"
                                    error={formState.errors.userId}
                                    options={(users?.list || [])
                                        .filter(
                                            (user: {
                                                status: string;
                                                role: string;
                                            }) =>
                                                user.status === 'active' &&
                                                user.role === 'admin'
                                        )
                                        .map(
                                            (user: {
                                                id: any;
                                                firstName: any;
                                                lastName: any;
                                            }) => ({
                                                value: user?.id,
                                                text: `${user?.firstName} ${user?.lastName}`,
                                            })
                                        )}
                                    registration={register('userId')}
                                />
                            </div>
                            <div className="py-4 flex flex-row justify-between">
                                <Button
                                    onClick={resetFilter}
                                    background="bg-gray-500"
                                >
                                    Reset
                                </Button>
                                <Button>Filter</Button>
                            </div>
                        </div>
                    )}
                </Form>
            </Modal>
        </>
    );
}

const ConfirmationModal = ({ open, setOpen, onConfirm }: any) => {
    return (
        <Modal show={open} setShow={setOpen} title="Confirm Submission">
            <p>Are you sure you want to submit this application?</p>
            <div className="flex gap-3  justify-end">
                <Button onClick={() => setOpen(false)} background="bg-gray-500">
                    Cancel
                </Button>
                <Button onClick={onConfirm}>Submit</Button>
            </div>
        </Modal>
    );
};
