import React, { useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { z } from 'zod';
import Button from '@/components/button';
import { Form } from '@/components/form';
import { InputField, SelectField } from '@/components/input';
import IconPencil from '@/components/Icon/IconPencil';
import { ExperiencePayload } from '@/core/types/experience'; // Updated import
import { EditExperienceInfo } from '@/core/hooks/experience';
import { ReferencePayload } from '@/core/types/reference';
import { EditReferenceInfo } from '@/core/hooks/Reference';
import { countries } from 'countries-list';
import AcademicInfo from '../../academics';

const experienceSchema = z.object({
    firstName: z.string().min(1, { message: 'First Name is required' }),
    lastName: z.string().min(1, { message: 'Last Name is required' }),
    company: z.string().min(1, { message: 'Company is required' }),
    position: z.string().min(1, { message: 'Position is required' }),
    relationship: z.string().min(1, { message: 'Relationship is required' }),
    email: z.string().email({ message: 'Invalid email format' }),
    phoneNumber: z.string().min(1, { message: 'Phone Number is required' }),
   
});

interface UpdateExperienceProps {
    refresh: (prev: any) => void;
    experience: ReferencePayload;
}

export const UpdateReferene: React.FC<UpdateExperienceProps> = ({
    refresh,
    experience,
}: any) => {
    const [modal, setModal] = React.useState(false);
    const { loadingEditReference, handleEditReference } =
        EditReferenceInfo(); // Updated hook

const [country, setCountry] = useState<string | any>('');
const [phoneCode, setPhoneCode] = useState<string | null>(null);

const countryOptions = Object.values(countries).map(country => ({
    value: country.name,
    text: country.name,
}));

function findCountryByName(countriesObject: any, countryName: any) {
    for (const countryCode in countriesObject) {
        if (countriesObject.hasOwnProperty(countryCode)) {
            const country = countriesObject[countryCode];
            if (country.name === countryName) {
                return country;
            }
        }
    }
    return null;
}
const handleCountryChange = (country: string | null) => {
    const selectedCountryData = findCountryByName(countries, country);
   
    if (selectedCountryData) {
        setPhoneCode(selectedCountryData?.phone[0]);
    } else {
        setPhoneCode(null);
    }
};
    return (
        <div className="">
            <div className="flex items-center justify-center">
                <button
                    type="button"
                    onClick={() => setModal(true)}
                    className="bg-warning text-gray-200 p-1 rounded"
                >
                    <IconPencil className="w-4" />
                </button>
            </div>
            <Transition appear show={modal} as={Fragment}>
                <Dialog as="div" open={modal} onClose={() => setModal(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0" />
                    </Transition.Child>
                    <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                        <div className="flex items-start justify-center min-h-screen px-4">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    as="div"
                                    className="panel border-0 p-0 rounded-lg overflow-hidden my-8 w-full max-w-lg text-black dark:text-white-dark"
                                >
                                    <div className="flex bg-[#fbfbfb] dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                                        <div className="text-lg flex flex-row justify-center w-full">
                                            Update Refrerence Information
                                        </div>
                                    </div>
                                    <div className="p-5">
                                        <Form<
                                            ReferencePayload,
                                            typeof experienceSchema
                                        >
                                            schema={experienceSchema}
                                            onSubmit={async data => {
                                                await handleEditReference(
                                                    data,
                                                    experience.id
                                                );
                                                refresh((prev: any) => !prev);
                                                setModal(false);
                                            }}
                                            className="mt-3"
                                        >
                                            {({ register, formState }) => (
                                                <>
                                                    <div className="px-4 grid grid-cols-2  gap-4 text-sm mb-3">
                                                        <InputField
                                                            type="text"
                                                            isLoading={
                                                                loadingEditReference
                                                            }
                                                            defaultValue={
                                                                experience?.firstName
                                                            }
                                                            label="Reference First name"
                                                            error={
                                                                formState.errors
                                                                    .firstName
                                                            }
                                                            registration={register(
                                                                'firstName'
                                                            )}
                                                        />
                                                        <InputField
                                                            type="text"
                                                            isLoading={
                                                                loadingEditReference
                                                            }
                                                            defaultValue={
                                                                experience?.lastName
                                                            }
                                                            label=" Reference Last Name"
                                                            error={
                                                                formState.errors
                                                                    .lastName
                                                            }
                                                            registration={register(
                                                                'lastName'
                                                            )}
                                                        />
                                                        <InputField
                                                            type="text"
                                                            isLoading={
                                                                loadingEditReference
                                                            }
                                                            defaultValue={
                                                                experience?.company
                                                            }
                                                            label="Company"
                                                            error={
                                                                formState.errors
                                                                    .company
                                                            }
                                                            registration={register(
                                                                'company'
                                                            )}
                                                        />
                                                        <InputField
                                                            type="text"
                                                            isLoading={
                                                                loadingEditReference
                                                            }
                                                            defaultValue={
                                                                experience?.position
                                                            }
                                                            label="Position"
                                                            error={
                                                                formState.errors
                                                                    .position
                                                            }
                                                            registration={register(
                                                                'position'
                                                            )}
                                                        />
                                                        <InputField
                                                            type="text"
                                                            isLoading={
                                                                loadingEditReference
                                                            }
                                                            defaultValue={
                                                                experience?.relationship
                                                            }
                                                            label="Relationship"
                                                            error={
                                                                formState.errors
                                                                    .relationship
                                                            }
                                                            registration={register(
                                                                'relationship'
                                                            )}
                                                        />
                                                        <InputField
                                                            type="text"
                                                            isLoading={
                                                                loadingEditReference
                                                            }
                                                            defaultValue={
                                                                experience?.email
                                                            }
                                                            label= "Reference Email"
                                                            error={
                                                                formState.errors
                                                                    .email
                                                            }
                                                            registration={register(
                                                                'email'
                                                            )}
                                                        />
                                                       <SelectField
                                                            label="Country"
                                                            placeholder="Select Country"
                                                            defaultValue={experience?.country}
                                                            onChange={e =>
                                                                {
                                                                    setCountry(e.target.value)
                                                                handleCountryChange(
                                                                    e.target
                                                                        .value
                                                                  )
                                                                 } }
                                                                
                                                            options={
                                                                countryOptions
                                                            }
                                                            error={
                                                                formState.errors
                                                                    .country
                                                            }
                                                        />

                                                        <InputField
                                                            type="text"
                                                            label="Phone Number"
                                                            placeholder="Phone Number"
                                                            
                                                            isLoading={
                                                                loadingEditReference
                                                            }
                                                            error={
                                                                formState.errors
                                                                    .phoneNumber
                                                            }
                                                            registration={register(
                                                                'phoneNumber'
                                                            )}
                                                            className="h-13"
                                                            defaultValue={
                                                                phoneCode
                                                                    ? `${phoneCode} `
                                                                    : experience?.phoneNumber
                                                            }
                                                        />
                                                       
                                                    </div>
                                                    <div className="my-3 flex justify-end">
                                                        <Button
                                                            disabled={
                                                                loadingEditReference
                                                            }
                                                        >
                                                            Submit
                                                        </Button>
                                                    </div>
                                                </>
                                            )}
                                        </Form>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
};
