import React, { useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { z } from 'zod';
import Button from '@/components/button';
import { Form } from '@/components/form';
import { InputField, SelectField } from '@/components/input';
import IconPencil from '@/components/Icon/IconPencil';
import { EditAddress } from '@/core/hooks/address';
import { ExperiencePayload } from '@/core/types/experience';
import IconPlus from '@/components/Icon/IconPlus';
import { useCreateExperiences } from '@/core/hooks/experience';
import { ReferencePayload } from '@/core/types/reference';
import { useCreateReferences } from '@/core/hooks/Reference';
import { countries } from 'countries-list';
import { count } from 'console';

export const referenceSchema = z.object({
    firstName: z.string().min(1, { message: 'First Name is required' }),
    lastName: z.string().min(1, { message: 'Last Name is required' }),
    company: z.string().min(1, { message: 'Company is required' }),
    position: z.string().min(1, { message: 'Position is required' }),
    relationship: z.string().min(1, { message: 'Relationship is required' }),
    email: z.string().email().min(1, { message: 'Email is required' }),
    phoneNumber: z.string().min(1, { message: 'Phone Number is required' }),
  
});

interface AddExperienceProps {
    refresh: (prev: any) => void;
}

export const AddReference: React.FC<AddExperienceProps> = ({ refresh }) => {
    const [modal, setModal] = React.useState(false);
    const { loadingCreate, handleCreateReference } = useCreateReferences(); // Updated hook

    const [country, setCountry] = useState<string | any>('');
    const [phoneCode, setPhoneCode] = useState<string | null>(null);

    const countryOptions = Object.values(countries).map(country => ({
        value: country.name,
        text: country.name,
    }));

    function findCountryByName(countriesObject: any, countryName: any) {
        for (const countryCode in countriesObject) {
            if (countriesObject.hasOwnProperty(countryCode)) {
                const country = countriesObject[countryCode];
                if (country.name === countryName) {
                    return country;
                }
            }
        }
        return null;
    }
    const handleCountryChange = (country: string | null) => {
        const selectedCountryData = findCountryByName(countries, country);
      
        if (selectedCountryData) {
            setPhoneCode(selectedCountryData?.phone[0]);
        } else {
            setPhoneCode(null);
        }
    };
    return (
        <div className="">
            <div className="flex items-center justify-center">
                <button
                    type="button"
                    onClick={() => setModal(true)}
                    className="btn-primary btn btn-sm"
                >
                    <IconPlus className="w-4" />
                </button>
            </div>
            <Transition appear show={modal} as={Fragment}>
                <Dialog as="div" open={modal} onClose={() => setModal(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0" />
                    </Transition.Child>
                    <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                        <div className="flex items-start justify-center min-h-screen px-4">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    as="div"
                                    className="panel border-0 p-0 rounded-lg overflow-hidden my-8 w-full max-w-xl text-black dark:text-white-dark"
                                >
                                    <div className="flex bg-[#fbfbfb] dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                                        <div className="text-lg flex flex-row justify-center w-full">
                                            Add Reference Information
                                        </div>
                                    </div>
                                    <div className="p-5">
                                        <Form<
                                            ReferencePayload,
                                            typeof referenceSchema
                                        >
                                            schema={referenceSchema}
                                            onSubmit={async data => {
                                                data.country = country
                                                await handleCreateReference(
                                                    data
                                                );
                                                refresh((prev: any) => !prev);
                                                setModal(false);
                                            }}
                                            className="mt-3"
                                        >
                                            {({ register, formState }) => (
                                                <>
                                                    <div className="px-4 grid grid-cols-2 gap-4 text-sm mb-3">
                                                        <InputField
                                                            type="text"
                                                            isLoading={
                                                                loadingCreate
                                                            }
                                                            placeholder="e.g., John"
                                                            label="First Name"
                                                            error={
                                                                formState.errors
                                                                    .firstName
                                                            }
                                                            registration={register(
                                                                'firstName'
                                                            )}
                                                        />
                                                        <InputField
                                                            type="text"
                                                            isLoading={
                                                                loadingCreate
                                                            }
                                                            placeholder="e.g., Doe"
                                                            label="Last Name"
                                                            error={
                                                                formState.errors
                                                                    .lastName
                                                            }
                                                            registration={register(
                                                                'lastName'
                                                            )}
                                                        />
                                                        <InputField
                                                            type="text"
                                                            isLoading={
                                                                loadingCreate
                                                            }
                                                            placeholder="e.g., XYZ Corp"
                                                            label="Company"
                                                            error={
                                                                formState.errors
                                                                    .company
                                                            }
                                                            registration={register(
                                                                'company'
                                                            )}
                                                        />
                                                        <InputField
                                                            type="text"
                                                            isLoading={
                                                                loadingCreate
                                                            }
                                                            placeholder="e.g., Manager"
                                                            label="Position"
                                                            error={
                                                                formState.errors
                                                                    .position
                                                            }
                                                            registration={register(
                                                                'position'
                                                            )}
                                                        />
                                                        <InputField
                                                            type="text"
                                                            isLoading={
                                                                loadingCreate
                                                            }
                                                            placeholder="e.g., Colleague"
                                                            label="Relationship"
                                                            error={
                                                                formState.errors
                                                                    .relationship
                                                            }
                                                            registration={register(
                                                                'relationship'
                                                            )}
                                                        />
                                                        <InputField
                                                            type="email"
                                                            isLoading={
                                                                loadingCreate
                                                            }
                                                            placeholder="e.g., john.doe@example.com"
                                                            label="Email"
                                                            error={
                                                                formState.errors
                                                                    .email
                                                            }
                                                            registration={register(
                                                                'email'
                                                            )}
                                                        />
                                                        <SelectField
                                                            label="Country"
                                                            placeholder="Select Country"
                                                            defaultValue={null}
                                                            onChange={e =>
                                                                {
                                                                    setCountry(e.target.value)
                                                                handleCountryChange(
                                                                    e.target
                                                                        .value
                                                                  )
                                                                 } }
                                                            options={
                                                                countryOptions
                                                            }
                                                            error={
                                                                formState.errors
                                                                    .country
                                                            }
                                                        />

                                                        <InputField
                                                            type="text"
                                                            label="Phone Number"
                                                            placeholder="Phone Number"
                                                            isLoading={
                                                                loadingCreate
                                                            }
                                                            error={
                                                                formState.errors
                                                                    .phoneNumber
                                                            }
                                                            registration={register(
                                                                'phoneNumber'
                                                            )}
                                                            className="h-13"
                                                            defaultValue={
                                                                phoneCode
                                                                    ? `${phoneCode} `
                                                                    : ''
                                                            }
                                                        />
                                                    </div>
                                                    <div className="my-3 flex justify-end">
                                                        <Button
                                                            disabled={
                                                                loadingCreate
                                                            }
                                                        >
                                                            Submit
                                                        </Button>
                                                    </div>
                                                </>
                                            )}
                                        </Form>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
};
