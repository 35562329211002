import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { storage } from '@/core/utils';
import { useLogout } from '../hooks/auth';
import { check_user } from '../api/auth';
import { addUser } from '@/store/slices/userslice';


export const CheckUser = () => {
    const token = storage.getToken();
    const dispatch = useDispatch();
    const { handleLogout } = useLogout();

    const fetchUser = async () => {
        if (token) {
            try {
                const user = await check_user();
                dispatch(addUser(user));
            } catch (error) {
                handleLogout();
            }
        } else {
            handleLogout();
        }
    };

    useEffect(() => {
        fetchUser();
    });
};



import { useState } from 'react';

export const useCheckUser = () => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const token = storage.getToken();
    const dispatch = useDispatch();
    const { handleLogout } = useLogout();

    useEffect(() => {
        const fetchUser = async () => {
            if (!token) {
                handleLogout(); // Logout if no token
                setLoading(false);
                return;
            }

            try {
                const userData = await check_user();
                setUser(userData);
                dispatch(addUser(userData)); // Update Redux store
            } catch (err) {
                console.error("Failed to fetch user:", err);
                setError("Failed to fetch user");
                handleLogout(); // Logout on failure
            } finally {
                setLoading(false); // Always set loading to false
            }
        };

        fetchUser();
    }, [token]); // Runs only when token or dependencies change

    return { user, loading, error };
};
