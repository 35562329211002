import Button from '@/components/button';
import { Form } from '@/components/form';
import { InputField, SelectField } from '@/components/input';
import {
    AdminCreateUser,
    useCreateUser,
    useGetAllUsers,
} from '@/core/hooks/auth';
import { UserPayload } from '@/core/types/auth';
import { z } from 'zod';
import { useEffect, useState } from 'react';
import IconHome from '@/components/Icon/IconHome';
import IconBell from '@/components/Icon/IconBell';
import { useGetEvaluators } from '@/core/hooks/evaluators';
import {
    GetApplications,
    useAssignEvaluetor,
    useAssignModerator,
} from '@/core/hooks/application';
import { ApplicationType } from '@/core/types/application';
const schema = z.object({
    email: z
        .string()
        .min(1, 'Email Name required')
        .refine(value => value.trim() === value, {
            message: 'Email cannot have leading or trailing spaces',
        }),

    firstName: z.string().min(1, { message: 'First name is required' }),
    lastName: z.string().min(1, { message: 'Last name is required' }),
    phoneNumber: z.string().min(1, { message: 'Phone  required' }),
});

function Addmoderator({ setActiveTab2 }: any) {
    const { loadingCreate, handleCreate } = AdminCreateUser();
    return (
        <>
            <div className=" my-2 "></div>
            <div className="my-4 ">
                <p className="text-lg font-bold ">Create moderator </p>
            </div>
            <div className=" panel">
                <div className="">
                    <Form<UserPayload, typeof schema>
                        onSubmit={async data => {
                            data.role = 'modulator';
                            await handleCreate(data);
                            setActiveTab2(2);
                        }}
                        schema={schema}
                    >
                        {({ register, formState }) => (
                            <div className="w-full">
                                <div className="px-4 flex flex-col gap-4 text-sm mb-3">
                                    <div className="flex flex-col md:flex-row gap-2">
                                        <InputField
                                            type="email"
                                            isLoading={loadingCreate}
                                            label="Email"
                                            error={formState.errors.email}
                                            registration={register('email')}
                                        />
                                    </div>
                                    <div className="flex flex-col md:flex-row gap-2">
                                        <InputField
                                            type="text"
                                            isLoading={loadingCreate}
                                            label="First Name"
                                            error={formState.errors.firstName}
                                            registration={register('firstName')}
                                        />
                                        <InputField
                                            type="text"
                                            isLoading={loadingCreate}
                                            label="Last Name"
                                            error={formState.errors.lastName}
                                            registration={register('lastName')}
                                        />
                                    </div>

                                    <div className="flex flex-col md:flex-row gap-2">
                                        <InputField
                                            type="text"
                                            isLoading={loadingCreate}
                                            label="Phone Number"
                                            error={formState.errors.phoneNumber}
                                            registration={register(
                                                'phoneNumber'
                                            )}
                                        />
                                    </div>

                                    <div className="my-3 flex justify-end">
                                        <Button disabled={loadingCreate}>
                                            Next
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Form>
                </div>
            </div>
        </>
    );
}
function AssignApplicaton() {
    const query = location.search;
    const pageSize = 1000000;
    const { loadingApplications, handleGetApplications, applications } =
        GetApplications();
    const { loadingUsers, handleGetUsers, users } = useGetAllUsers();
    const { handleAssignModeratorToApplication, loadingAssign } =
        useAssignModerator();
    const [selectedApplications, setSelectedApplications] = useState<string>('');
    const [selectedUserId, setSelectedUserId] = useState<string>('');
    const [userIdError, setUserIdError] = useState<string>('');
    const [applicationIdError, setApplicationIdError] = useState<string>('');

    const updatePageSizeInQuery = (query: string, pageSize: number): string => {
        const urlParams = new URLSearchParams(query);
        urlParams.set('pageSize', pageSize.toString());
        urlParams.set('role', 'modulator');
        return '?' + urlParams.toString();
    };

    const PageSize = 1000000;
    useEffect(() => {
        const updatedQuery = updatePageSizeInQuery(query, PageSize);
        handleGetUsers(updatedQuery);
        handleGetApplications(updatedQuery);
    }, []);

    const handleFormSubmit = () => {
        if (!selectedUserId) {
            setUserIdError('No Moderator selected');
        } else {
            setUserIdError('');
        }
        if (selectedApplications.length === 0) {
            setApplicationIdError('Application not selected');
        } else {
            setApplicationIdError('');
        }

        if (!selectedUserId || selectedApplications.length === 0) {
            return;
        }

        const requestData: any = {
            modulatorId: selectedUserId,
            applicationId: selectedApplications,
        };
        handleAssignModeratorToApplication(requestData);
       
    };

    return (
        <div>
            <div className="p-5">
                <Form onSubmit={handleFormSubmit}>
                    {({ register, formState }) => (
                        <div className=''>
                            <div className="mb-3">
                                <SelectField
                                    placeholder="Select moderator"
                                    isLoading={loadingApplications}
                                    label="moderator"
                                    options={(users?.list || []).map(
                                        (category: {
                                            id: any;
                                            firstName: any;
                                            lastName: any;
                                        }) => ({
                                            value: category?.id,
                                            text: `${category?.firstName} ${category?.lastName}`,
                                        })
                                    )}
                                    onChange={e =>
                                        setSelectedUserId(e.target.value)
                                    }
                                />
                                {userIdError && (
                                    <span className="text-red-500">
                                        {userIdError}
                                    </span>
                                )}
                            </div>

                            <div className="mb-3">
                                <SelectField
                                    placeholder="Select Application"
                                    isLoading={loadingApplications}
                                    label="Application"
                                    options={(applications?.list || []).map(
                                        (application: any) => ({
                                            value: application.id,
                                            text: application.title,
                                        })
                                    )}
                                    onChange={e =>
                                        setSelectedApplications(
                                            e.target.value,
                                        )
                                    }
                                />
                                {applicationIdError && (
                                    <span className="text-red-500">
                                        {applicationIdError}
                                    </span>
                                )}
                            </div>

                            <div className="my-3 flex justify-end">
                                <Button
                                    type="submit"
                                    disabled={
                                        loadingApplications ||
                                        loadingApplications
                                    }
                                >
                                    Assign
                                </Button>
                            </div>
                        </div>
                    )}
                </Form>
            </div>
        </div>
    );
}

export default function CreateModulator() {
    const [activeTab2, setActiveTab2] = useState<any>(1);

    return (
        <div className="inline-block w-full">
            <ul className="mb-5 grid grid-cols-3">
                <li>
                    <div
                        className={`${
                            activeTab2 === 1 ? '!bg-primary text-white' : ''
                        }
                flex items-center justify-center rounded-full bg-[#f3f2ee] p-2.5 dark:bg-[#1b2e4b]`}
                        onClick={() => setActiveTab2(1)}
                    >
                        <IconHome />
                    </div>
                    <span className="text-center block mt-2">
                        Create Moderator
                    </span>
                </li>
                <li>
                    <div
                        className={`${
                            activeTab2 === 2 ? '!bg-primary text-white' : ''
                        }
                flex items-center justify-center rounded-full bg-[#f3f2ee] p-2.5 dark:bg-[#1b2e4b]`}
                        onClick={() => setActiveTab2(2)}
                    >
                        <IconBell />
                    </div>
                    <span className="text-center block mt-2">
                        Assign Application{' '}
                    </span>
                </li>
            </ul>
            <div>
                <p className="mb-5">
                    {activeTab2 === 1 && (
                        <Addmoderator setActiveTab2={setActiveTab2} />
                    )}
                </p>

                <p className="mb-5">
                    {activeTab2 === 2 && <AssignApplicaton />}
                </p>
            </div>
            <div className="flex justify-between">
                <button
                    type="button"
                    className={`btn btn-primary ${
                        activeTab2 === 1 ? 'hidden' : ''
                    }`}
                    onClick={() => setActiveTab2(activeTab2 === 3 ? 2 : 1)}
                >
                    Back
                </button>
            </div>
        </div>
    );
}
