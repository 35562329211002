import { useEffect, useState } from 'react';
import { storage } from '../utils';
import { check_user } from '../api/auth';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from '@/store';
import logo from '@/assets/aims_logo.png';
import { useCheckUser } from '../utils/UseUser';

export const RoleProtector = ({
    element,
    role,
}: {
    element: JSX.Element;
    role: string;
}): JSX.Element => {
    const token = storage.getToken();

    const { user }: any = useCheckUser();
    console.log(user);

    if (user?.role !== role)
        return (
            <div
                className={
                    'h-screen flex justify-center items-center w-screen dark:bg-black absolute'
                }
            >
                <div
                    className={`w-14 h-14 text-primary relative`}
                    role="status"
                >
                    <img
                        src={logo}
                        className={'animate-slow-ping w-full h-full '}
                        alt={'loading'}
                    />
                </div>
            </div>
        );

    return element;
};
