import Button from '@/components/button';
import { Form } from '@/components/form';
import { InputField, TextArea } from '@/components/input';
import { GetApplications } from '@/core/hooks/application';
import { useSendEmail } from '@/core/hooks/notifications';
import {
    NotificationPayload,
    NotificationType,
} from '@/core/types/notifications';
import { useAppSelector } from '@/store';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { z } from 'zod';

export enum TheStatus {
    PENDING = 'pending',
    REJECT = 'reject',
    APPROVED = 'approved',
    ACCEPTED_SCHOLARSHIP = 'accepted_scholarship',
    ACCEPTED_SELF_FUNDED = 'accepted_self_funded',
    ACCEPTED_VIRTUAL_PARTICIPANT = 'accepted_virtual_participant',
    ACCEPTED_LOCAL_PARTICIPANT = 'accepted_local_participant',
    SHORT_LISTED_FOR_INTERVIEW = 'shortlisted_for_interview',
}

const experienceSchema = z.object({
    applicationId: z.string().min(1, { message: 'Select application' }),
    title: z.string().min(1, { message: 'Title is required' }),
    sendType: z.string().min(1, { message: 'Type is required' }),
    subject: z.string().min(1, { message: 'Subject is required' }),
    message: z.string().min(1, { message: 'Email body is required' }),
});

export default function Notifications() {
    const { userAccount: user }: any = useAppSelector(store => store.user);
    const { loadingSendEmail, handleSendEmail } = useSendEmail();
    const [sendType, setSendType] = useState('');
    const [status, setStatus] = useState('');
    const [statusError, setStatusError] = useState('');

    const { loadingApplications, handleGetApplications, applications } = GetApplications();

    const query = location.search;

    useEffect(() => {
        handleGetApplications(query);
    }, [query]);
    

    return (
        <div>
            <div className="my-2 ">
                <ol className="flex text-primary font-semibold dark:text-white-dark">
                    <li className="bg-[#ebedf2] ltr:rounded-l-md rtl:rounded-r-md dark:bg-[#1b2e4b]">
                        <Link
                            to={`/account/${user?.role}`}
                            className="p-1.5 ltr:pl-3 rtl:pr-3 ltr:pr-2 rtl:pl-2 relative  h-full flex items-center before:absolute ltr:before:-right-[15px] rtl:before:-left-[15px] rtl:before:rotate-180 before:inset-y-0 before:m-auto before:w-0 before:h-0 before:border-[16px] before:border-l-[15px] before:border-r-0 before:border-t-transparent before:border-b-transparent before:border-l-[#ebedf2] before:z-[1] dark:before:border-l-[#1b2e4b] hover:text-primary/70 dark:hover:text-white-dark/70"
                        >
                            Home
                        </Link>
                    </li>
                    <li className="bg-[#ebedf2] dark:bg-[#1b2e4b]">
                        <button className="bg-primary text-white-light p-1.5 ltr:pl-6 rtl:pr-6 ltr:pr-2 rtl:pl-2 relative  h-full flex items-center before:absolute ltr:before:-right-[15px] rtl:before:-left-[15px] rtl:before:rotate-180 before:inset-y-0 before:m-auto before:w-0 before:h-0 before:border-[16px] before:border-l-[15px] before:border-r-0 before:border-t-transparent before:border-b-transparent before:border-l-primary before:z-[1]">
                            Notifications
                        </button>
                    </li>
                </ol>
            </div>

            <div className="my-5 panel">
                <Form<NotificationPayload, typeof experienceSchema>
                    schema={experienceSchema}
                    onSubmit={async data => {
                        if (sendType === 'application_decision') {
                            if (status === '') {
                                setStatusError('Please select Status');
                            }
                            data.applicationStatus = status;
                        }
                    
                        await handleSendEmail(data);
                    }}
                    className="mt-3"
                >
                    {({ register, formState }) => (
                        <>
                            <h1 className="text-xl font-bold mb-4">
                                Send Email
                            </h1>
                            <div className="mb-4">
                                <label className="block text-sm dark:text-white-light">
                                    Application
                                </label>
                                <select
                                    {...register('applicationId')}
                                    className={`mt-1 form-select ${
                                        formState.errors.applicationId
                                            ? 'border-red-500'
                                            : ''
                                    } rounded-md shadow-sm`}
                                    disabled={loadingSendEmail || loadingApplications}
                                >
                                    <option value="">Select Application</option>
                                    {(applications?.list || []).map(
                                        (app: { id: any; title: any }) => (
                                            <option key={app.id} value={app.id}>
                                                {app.title}
                                            </option>
                                        )
                                    )}
                                </select>
                                {formState.errors.applicationId && (
                                    <p className="text-red-500 text-sm mt-1">
                                        {formState.errors.applicationId.message}
                                    </p>
                                )}
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm  dark:text-white-light">
                                    Receivers
                                </label>
                                <select
                                    {...register('sendType')}
                                    className={`mt-1 form-select ${
                                        formState.errors.sendType
                                            ? 'border-red-500'
                                            : ''
                                    } rounded-md shadow-sm`}
                                    disabled={loadingSendEmail || loadingApplications}
                                    onChange={(e: any) => setSendType(e.target.value)}
                                >
                                    <option value="">Select email receivers</option>
                                    <option value="all_applicants">All applicants</option>
                                    <option value="submitted_applicants">Submitted applicants</option>
                                    <option value="un_submitted_applicants">Unsubmitted applicants</option>
                                    <option value="application_decision">
                                        Send to a specific application decision
                                    </option>
                                </select>
                                {formState.errors.sendType && (
                                    <p className="text-red-500 text-sm mt-1">
                                        {formState.errors.sendType.message}
                                    </p>
                                )}
                            </div>
                            {sendType === 'application_decision' && (
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">
                                        Status
                                    </label>
                                    <select
                                        className='form-select'
                                        onChange={(e: any) => setStatus(e.target.value)}
                                        disabled={loadingSendEmail || loadingApplications}
                                    >
                                        <option value="">Select Status</option>
                                        <option value={TheStatus.PENDING}>Pending</option>
                                        <option value={TheStatus.REJECT}>Reject</option>
                                        <option value={TheStatus.APPROVED}>Approved</option>
                                        <option value={TheStatus.ACCEPTED_SCHOLARSHIP}>
                                            Accepted Scholarship
                                        </option>
                                        <option value={TheStatus.ACCEPTED_SELF_FUNDED}>
                                            Accepted Self Funded
                                        </option>
                                        <option value={TheStatus.ACCEPTED_VIRTUAL_PARTICIPANT}>
                                            Accepted Virtual Participant
                                        </option>
                                        <option value={TheStatus.ACCEPTED_LOCAL_PARTICIPANT}>
                                            Accepted Local Participant
                                        </option>
                                        <option value={TheStatus.SHORT_LISTED_FOR_INTERVIEW}>
                                            Shortlisted for Interview
                                        </option>
                                    </select>
                                    {statusError && (
                                        <p className="text-red-500 text-sm mt-1">{statusError}</p>
                                    )}
                                </div>
                            )}
                            <div className="mb-4 mt-1">
                                <InputField
                                    type="text"
                                    label="Subject"
                                    placeholder="Subject"
                                    isLoading={loadingSendEmail}
                                    error={formState.errors.subject}
                                    registration={register('subject')}
                                />
                            </div>
                            <div className="mb-4 mt-1">
                                <InputField
                                    type="text"
                                    label="Title"
                                    placeholder="Title"
                                    isLoading={loadingSendEmail}
                                    error={formState.errors.title}
                                    registration={register('title')}
                                    className="h-16"
                                />
                            </div>
                            <div className="mb-4 mt-1">
                                <TextArea
                                    label="Message or Email body"
                                    className="h-64"
                                    placeholder="Message"
                                    isLoading={loadingSendEmail}
                                    error={formState.errors.message}
                                    registration={register('message')}
                                />
                            </div>
                            <div className="mb-4">
                                <Button disabled={loadingSendEmail}>
                                    Send email
                                </Button>
                            </div>
                        </>
                    )}
                </Form>
            </div>
        </div>
    );
}
