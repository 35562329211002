import { useApplyApplication, SubmitApplication } from "@/core/hooks/application";
import { getMyProfile } from "@/core/hooks/auth";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MyProfile from "../../view_profile";

export default function ApplicationSubmissionDetails() {
    const { applyApplication, loadingApply } = useApplyApplication();
    const { application_id }: any = useParams();
    const { loadingPrifile, handleGetProfile, profile } = getMyProfile();

    useEffect(() => {
        handleGetProfile();
    }, []);

    const application = profile?.applications?.find(
        (application: { id: any }) => application.id === application_id
    );

    const { loadingSubmit, submitApplication } = SubmitApplication();

    const [confirmationOpen, setConfirmationOpen] = useState(false); 
    const handleApply = (application_id: any) => {
        setConfirmationOpen(true);
    };

    return (
        <>
            <MyProfile />
            <div className=" border border-primary mt-3 ">
                <div className=" uppercase text-lg font-bold p-2  bg-primary flex flex-row justify-center  text-white ">
                    Application Details
                </div>
                <div className="p-3 ">
                    <h1 className="text-lg font-bold mb-4 text-primary">
                        {application?.title}
                    </h1>

                    <div
                        className=" dark:text-gray-400  "
                        dangerouslySetInnerHTML={{
                            __html: application?.description || '',
                        }}
                    />
                    {application?.topics &&
                        application?.topics.map((topic: any) => (
                            <div>
                                <p className=" text-xl font-bold  ">Topic</p>
                                <div>
                                    <h3 className="text-lg font-bold mb-2   file: mx-2  text-gray-800 dark:text-gray-400">
                                        Name:{' '}
                                        <span className="font-normal ">
                                            {topic?.name}
                                        </span>
                                    </h3>
                                    <p className="mx-2 font-bold   text-lg text-gray-800 dark:text-gray-400">
                                        Description:{' '}
                                        <span className=" font-normal">
                                            {topic?.description}
                                        </span>
                                    </p>
                                </div>
                                <div>
                                    <p className="my-2  text-lg font-bold  ">
                                        Questions
                                    </p>
                                    <div className="flex flex-col space-y-4">
                                        {topic?.questions?.map(
                                            (question: any, index: number) => (
                                                <div
                                                    key={question.id}
                                                    className="flex flex-col  items-start space-y-2"
                                                >
                                                    <div className="flex flex-row ">
                                                        <div className=" bg-gray-200  dark:bg-gray-800 p-3 rounded-lg max-w-md">
                                                            <div>
                                                                <p className=" capitalize ">
                                                                    {' '}
                                                                    <span className="text-lg font-bold ">
                                                                        {index +
                                                                            1}
                                                                        .
                                                                    </span>{' '}
                                                                    {
                                                                        question.title
                                                                    }
                                                                </p>
                                                                <p className=" ml-4 ">
                                                                    {
                                                                        question.description
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className=" ml-4 ">
                                                        <div className=" font-bold ">
                                                            <p className="">
                                                                Answer
                                                            </p>
                                                        </div>

                                                        {question?.answers?.map(
                                                            (answer: any) => (
                                                                <div
                                                                    key={
                                                                        answer.id
                                                                    }
                                                                    className="flex  mt-1   flex-col items-end"
                                                                >
                                                                    <div className="bg-gray-100 dark:bg-gray-800  p-3 rounded-lg">
                                                                        <p className="">
                                                                            {
                                                                                answer.answer
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}

                    <div className="mt-4 ">
                        {application?.suggestedTopics.length > 0 && (
                            <p className=" text-lg font-bold  ">
                                Suggested Topics
                            </p>
                        )}
                        {application?.suggestedTopics &&
                            application?.suggestedTopics.map(
                                (topic: any, index: any) => (
                                    <div>
                                        <div className="mt-2 ">
                                            <h3 className="text-lg  font-bold  mx-2  text-gray-800 dark:text-gray-400">
                                                {index + 1}. name:{' '}
                                                <span className=" ">
                                                    {topic?.title}
                                                </span>
                                            </h3>
                                            <p className="mx-2 font-bold  text-md    text-gray-600 dark:text-gray-400">
                                                Description{' '}
                                                <span className="font-normal">
                                                    {topic?.description}
                                                </span>
                                            </p>
                                        </div>
                                        <div>
                                            <div className="flex flex-col px-4">
                                                <div className="  text-lg font-bold ">
                                                    <p className="">
                                                        Skills Needed
                                                    </p>
                                                </div>

                                                <div className="flex flex-wrap mt-2">
                                                    {topic?.skills_needed?.map(
                                                        (skill: any) => (
                                                            <div
                                                                key={
                                                                    skill?.name
                                                                }
                                                                className="bg-primary text-white py-1 px-2 rounded-full mr-2 mb-2"
                                                            >
                                                                # {skill?.name}
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            )}
                    </div>
                </div>
            </div>
            
        </>
    );
}