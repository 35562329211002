import IconPlus from '@/components/Icon/IconPlus';
import Button from '@/components/button';
import { Form } from '@/components/form';
import { InputField, SelectField, TextArea } from '@/components/input';
import { GetApplications } from '@/core/hooks/application';
import { GetCategories } from '@/core/hooks/category';
import { useCreateTopic } from '@/core/hooks/topics';
import { CategoryType } from '@/core/types/category';
import { TopicPayload } from '@/core/types/topics';
import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useEffect } from 'react';
import { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useParams } from 'react-router-dom';
import { z } from 'zod';

const experienceSchema = z.object({
    name: z.string().min(1, { message: 'Topic name is required' }),
    description: z.string().min(1, { message: 'Description is required' }),
    type: z.string().min(1, { message: 'Type is required' }),
   
});
export default function AddTopic({ refresh }: any) {
    const { applicationId }: any = useParams();
  
    const { loadingCreate, handleCreateTopic } = useCreateTopic();
    const [modal, setModal] = React.useState(false);
    const [value, setValue] = useState('Add  Instructions here')
    const { loadingApplications, handleGetApplications, applications } =
        GetApplications();

    const { loadingCategories, handleGetCategories, categories } =
        GetCategories();

    const query = location.search;
     
    useEffect(() => {
        handleGetApplications(query);
        handleGetCategories(query);
    }, [query]);
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

    return (
        <div>
            <div className="">
                <div className="flex items-center justify-center">
                    <button
                        type="button"
                        onClick={() => setModal(true)}
                        className="btn btn-primary  text-white px-4 py-2   "
                    >
                        <IconPlus className="w-4" /> New Topic
                    </button>
                </div>
                <Transition appear show={modal} as={Fragment}>
                    <Dialog
                        as="div"
                        open={modal}
                        onClose={() => setModal(false)}
                    >
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0" />
                        </Transition.Child>
                        <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                            <div className="flex items-start justify-center min-h-screen px-4">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <Dialog.Panel
                                        as="div"
                                        className="panel border-0 p-0 rounded-lg overflow-hidden my-8 w-full max-w-4xl text-black dark:text-white-dark"
                                    >
                                        <div className="flex bg-[#fbfbfb] dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                                            <div className="text-lg flex flex-row justify-center w-full">
                                                Add Topic
                                            </div>
                                        </div>
                                        <div className="p-5">
                                            <Form<
                                                TopicPayload,
                                                typeof experienceSchema
                                            >
                                                schema={experienceSchema}
                                                onSubmit={async data => {
                                                    data.applicationId = applicationId
                                                    data.categoryIds =
                                                        selectedCategories;
                                                    data.instruction_note =
                                                        value;
                                                    await handleCreateTopic(
                                                        data
                                                    );
                                                    refresh(
                                                        (prev: any) => !prev
                                                    );
                                                    setModal(false);
                                                }}
                                                className="mt-3"
                                            >
                                                {({ register, formState }) => (
                                                    <>
                                                        <div className="px-4  text-sm mb-3">
                                                            <div className="   gap-4 grid grid-cols-2 ">
                                                                <InputField
                                                                    type="text"
                                                                    isLoading={
                                                                        loadingCreate
                                                                    }
                                                                    placeholder="Topic title"
                                                                    label="Topic Name"
                                                                    error={
                                                                        formState
                                                                            .errors
                                                                            .name
                                                                    }
                                                                    registration={register(
                                                                        'name'
                                                                    )}
                                                                />

                                                                <InputField
                                                                    type="text"
                                                                    isLoading={
                                                                        loadingCreate
                                                                    }
                                                                    placeholder="Type"
                                                                    label="Topic Type"
                                                                    error={
                                                                        formState
                                                                            .errors
                                                                            .type
                                                                    }
                                                                    registration={register(
                                                                        'type'
                                                                    )}
                                                                />
                                                                

                                                                <div className="my-5  h-[100px] overflow-y-scroll form-input  ">
                                                                    <label htmlFor="select category">
                                                                        {' '}
                                                                        category
                                                                    </label>

                                                                    <label className="flex flex-col  mx-2 ">
                                                                        {categories?.list.map(
                                                                            (
                                                                                category: CategoryType
                                                                            ) => (
                                                                                <>
                                                                                    {' '}
                                                                                    <div className="flex flex-row justify-start mb-4">
                                                                                        <div>
                                                                                            <input
                                                                                                id={`checkbox-${category.id}`}
                                                                                                type="checkbox"
                                                                                                value={
                                                                                                    category.id
                                                                                                }
                                                                                                className="form-checkbox w-4 h-4"
                                                                                                onChange={(
                                                                                                    e: React.ChangeEvent<HTMLInputElement>
                                                                                                ) => {
                                                                                                    const categoryId =
                                                                                                        e
                                                                                                            .target
                                                                                                            .value;
                                                                                                    setSelectedCategories(
                                                                                                        prevSelected => {
                                                                                                            if (
                                                                                                                prevSelected.includes(
                                                                                                                    categoryId
                                                                                                                )
                                                                                                            ) {
                                                                                                                return prevSelected.filter(
                                                                                                                    id =>
                                                                                                                        id !==
                                                                                                                        categoryId
                                                                                                                );
                                                                                                            } else {
                                                                                                                return [
                                                                                                                    ...prevSelected,
                                                                                                                    categoryId,
                                                                                                                ];
                                                                                                            }
                                                                                                        }
                                                                                                    );
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                        <div>
                                                                                            <label className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                                                                                                {
                                                                                                    category.name
                                                                                                }{' '}
                                                                                            </label>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        )}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <TextArea
                                                                isLoading={
                                                                    loadingCreate
                                                                }
                                                                placeholder="Description"
                                                                label="Description"
                                                                className=" h-36 "
                                                                error={
                                                                    formState
                                                                        .errors
                                                                        .description
                                                                }
                                                                registration={register(
                                                                    'description'
                                                                )}
                                                            />
                                                        </div>

                                                        <div className="p-4 ">
                                                            <label className="mb-1 dark:text-white-light">
                                                                Instruction
                                                                Notes
                                                            </label>
                                                            <ReactQuill
                                                                theme="snow"
                                                                value={value}
                                                                onChange={val =>
                                                                    setValue(
                                                                        val
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div className="my-3 flex justify-end">
                                                            <Button
                                                                disabled={
                                                                    loadingCreate
                                                                }
                                                            >
                                                                Save
                                                            </Button>
                                                        </div>
                                                    </>
                                                )}
                                            </Form>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition>
            </div>
        </div>
    );
}
