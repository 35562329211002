import { AxiosErrorHandler, CustomError } from '@/core/utils/request';
import PRIVATE_API from '../axios';

interface SocioEconomicPayload {
  parentsStatus: number;
  mainBreadwinner: number;
  householdDependents: number;
  houseOwnership: number;
  landOwnership: number;
  residenceType: number;
  roofingMaterial: number;
  householdIncome: number;
  governmentSupport: number;
  cookingEnergy: number;
  schoolDropoutReason: number;
}

export const createSocioEconomic = async (payload: SocioEconomicPayload): Promise<any> => {
  try {
    const request = await PRIVATE_API.post('/socio-economic', payload);
    return request.data;
  } catch (error: any) {
    throw new CustomError(AxiosErrorHandler(error));
  }
};

export const getSocioEconomicById = async (id: string): Promise<any> => {
  try {
    const request = await PRIVATE_API.get(`/socio-economic/${id}`);
    return request.data;
  } catch (error: any) {
    throw new CustomError(AxiosErrorHandler(error));
  }
};

export const getSocioEconomicByUser = async (userId: string): Promise<any> => {
  try {
    const request = await PRIVATE_API.get(`/socio-economic/user/${userId}`);
    return request.data;
  } catch (error: any) {
    throw new CustomError(AxiosErrorHandler(error));
  }
};

export const updateSocioEconomic = async (id: string, payload: Partial<SocioEconomicPayload>): Promise<any> => {
  try {
    const request = await PRIVATE_API.patch(`/socio-economic/${id}`, payload);
    return request.data;
  } catch (error: any) {
    throw new CustomError(AxiosErrorHandler(error));
  }
};

export const deleteSocioEconomic = async (id: string): Promise<any> => {
  try {
    const request = await PRIVATE_API.delete(`/socio-economic/${id}`);
    return request.data;
  } catch (error: any) {
    throw new CustomError(AxiosErrorHandler(error));
  }
};
