import { useAppSelector } from '@/store';
import Chart from 'react-apexcharts';

export default function Charts() {
    const { userAccount } = useAppSelector(state => state).user;

    return (
        <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-3 bg-gray-10  p-3">
            <>
                <Graph name="Offline Tickets" values={12} color="#3371FF" />
                <Graph name="Online Tickets" values={123} color="#AA3242" />
                {userAccount?.role === 'admin' ||
                    (userAccount?.role === 'manager' && (
                        <Graph
                            name="Scanned Tickets"
                            values={12}
                            color="#FF2134"
                        />
                    ))}
            </>
        </div>
    );
}

type GraphProps = {
    name: string;
    values: number;
    color: string;
};
function Graph(props: GraphProps) {
    const isDark = useAppSelector(
        state =>
            state.themeConfig.theme === 'dark' || state.themeConfig.isDarkMode
    );
    const isRtl =
        useAppSelector(state => state.themeConfig.rtlClass) === 'rtl'
            ? true
            : false;

    const series: any = [];

    const options: any = {
        series: series,
        options: {
            title: {
                text: `${props.name} Tickets`,
                style: {
                    fontWeight: 'normal',
                    color: '#805dca',
                },
            },
            chart: {
                height: 300,
                type: 'bar',
                zoom: {
                    enabled: false,
                },
                toolbar: {
                    show: false,
                },
            },
            colors: ['#805dca', '#e7515a'],
            dataLabels: {
                enabled: false,
            },

            stroke: {
                show: true,
                width: 2,
                colors: ['transparent'],
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded',
                },
            },
            grid: {
                borderColor: isDark ? '#191e3a' : '#e0e6ed',
            },
            xaxis: {},
            yaxis: {
                opposite: isRtl ? true : false,
                labels: {
                    offsetX: isRtl ? -10 : 0,
                },
            },
            legend: {
                position: 'bottom',
                offsetY: 40,
            },
            tooltip: {
                theme: isDark ? 'dark' : 'light',
                y: {
                    formatter: (value: number) => `${value * 1000} RWF`, // Customize the y-axis value format
                },
            },
        },
    };
    return (
        <div className="relative w-full  rounded shadow-md p-2">
            <Chart options={options.options} series={series} type="bar" />
        </div>
    );
}

function DummyGraph() {
    return (
        <div className="relative h-44 animate-pulse w-full  rounded shadow-md border border-gray-50/20 p-2"></div>
    );
}
