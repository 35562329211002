import Button from '@/components/button';
import { Form } from '@/components/form';
import { InputField, SelectField, TextArea } from '@/components/input';
import {
    GetApplications,
    useCreateApplications,
} from '@/core/hooks/application';
import { GetCategories } from '@/core/hooks/category';
import { useCreateTopic } from '@/core/hooks/topics';
import { ApplicationPayload } from '@/core/types/application';
import { TopicPayload } from '@/core/types/topics';
import React, { Fragment, useEffect } from 'react';

import { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { z } from 'zod';
import Applications from '../../applications';
import { SuggestedTopicPayload } from '@/core/types/suggested-topics';
import { useCreateSuggestedTopic } from '@/core/hooks/suggested-topics';
import { Dialog, Transition } from '@headlessui/react';
import IconPlus from '@/components/Icon/IconPlus';
import { useParams } from 'react-router-dom';

const SuggestedTopicShema = z.object({
    title: z.string().min(1, { message: 'Topic Tilte is required' }),
    description: z.string().min(1, { message: 'Description is required' }),
    categoryId: z.string().min(1, { message: 'Category is required' }),
});
export default function CreateSuggestedTopic({ refresh }: any) {
    const { application_id }: any = useParams();
   
    const [modal, setModal] = React.useState(false);
    const { loadingCreateSuggestedTopic, handleCreateSuggestedTopic } =
        useCreateSuggestedTopic();
    const { loadingApplications, handleGetApplications, applications } =
        GetApplications();

    const { loadingCategories, handleGetCategories, categories } =
        GetCategories();

    const query = location.search;
     
    useEffect(() => {
        handleGetApplications(query);
        handleGetCategories(query);
    }, [query]);

    const [categoryData, setCategoryData] = useState<any>({
        name: '',
        description: '',
        skills_required: [{ name: '' }],
    });

    const handleInputChange = (
        field: string,
        value: string,
        index?: number
    ) => {
    
        setCategoryData((prev: { skills_required: any[] }) => {
            if (index !== undefined && prev.skills_required[index]) {
                const updatedSkills = [...prev.skills_required];
                updatedSkills[index] = { name: value };
                return { ...prev, skills_required: updatedSkills };
            } else {
                return { ...prev, [field]: value };
            }
        });
    };

    const addSkill = () => {
        setCategoryData((prev: { skills_required: any }) => ({
            ...prev,
            skills_required: [...prev.skills_required, { name: '' }],
        }));
    };

    const removeSkill = (index: number) => {
        setCategoryData((prev: { skills_required: any }) => {
            const updatedSkills = [...prev.skills_required];
            updatedSkills.splice(index, 1);
            return { ...prev, skills_required: updatedSkills };
        });
    };

    return (
        <div>
            <div className="">
                <div className="flex items-center justify-center">
                    <button
                        type="button"
                        onClick={() => setModal(true)}
                        className="btn-primary btn btn-sm"
                    >
                        <IconPlus className="w-4" />
                        Suggest topic
                    </button>
                </div>
                <Transition appear show={modal} as={Fragment}>
                    <Dialog
                        as="div"
                        open={modal}
                        onClose={() => setModal(false)}
                    >
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0" />
                        </Transition.Child>
                        <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                            <div className="flex items-start justify-center min-h-screen px-4">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <Dialog.Panel
                                        as="div"
                                        className="panel border-0 p-0 rounded-lg overflow-hidden my-8 w-full max-w-3xl text-black dark:text-white-dark"
                                    >
                                        <div className="flex bg-[#fbfbfb] dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                                            <div className="text-lg flex flex-row justify-center w-full">
                                                Add Suggested Topic
                                            </div>
                                        </div>
                                        <div className="p-5">
                                            <Form<
                                                SuggestedTopicPayload,
                                                typeof SuggestedTopicShema
                                            >
                                                schema={SuggestedTopicShema}
                                                onSubmit={async data => {
                                                    data.applicationId =
                                                        application_id;
                                                    data.skills_needed =
                                                        categoryData.skills_required;
                                                    await handleCreateSuggestedTopic(
                                                        data
                                                    );
                                                    refresh(
                                                        (prev: any) => !prev
                                                    );
                                                    setModal(false);
                                                }}
                                                className="mt-3"
                                            >
                                                {({ register, formState }) => (
                                                    <>
                                                        <div className="px-4 grid grid-cols-2 gap-4 text-sm mb-3">
                                                            <InputField
                                                                type="text"
                                                                isLoading={
                                                                    loadingCreateSuggestedTopic
                                                                }
                                                                placeholder="Topic title"
                                                                label="Topic Name"
                                                                error={
                                                                    formState
                                                                        .errors
                                                                        .title
                                                                }
                                                                registration={register(
                                                                    'title'
                                                                )}
                                                            />

                                                            <SelectField
                                                                placeholder="Select Category"
                                                                isLoading={
                                                                    loadingCreateSuggestedTopic ||
                                                                    loadingCategories
                                                                }
                                                                label="Category"
                                                                error={
                                                                    formState
                                                                        .errors
                                                                        .categoryId
                                                                }
                                                                options={(
                                                                    categories?.list ||
                                                                    []
                                                                ).map(
                                                                    (category: {
                                                                        id: any;
                                                                        name: any;
                                                                    }) => ({
                                                                        value: category?.id,
                                                                        text: category?.name,
                                                                    })
                                                                )}
                                                                registration={register(
                                                                    'categoryId'
                                                                )}
                                                            />
                                                            <div>
                                                                <label
                                                                    htmlFor="skills"
                                                                    className="block text-sm font-medium text-gray-700"
                                                                >
                                                                    Skills
                                                                    Required
                                                                </label>
                                                                {categoryData.skills_required.map(
                                                                    (
                                                                        skill: any,
                                                                        index: any
                                                                    ) => (
                                                                        <div
                                                                            key={
                                                                                index
                                                                            }
                                                                            className="flex items-center mt-2"
                                                                        >
                                                                            <InputField
                                                                                type="text"
                                                                                isLoading={
                                                                                    loadingCreateSuggestedTopic
                                                                                }
                                                                                placeholder={`Skill #${
                                                                                    index +
                                                                                    1
                                                                                }`}
                                                                                label=""
                                                                                onChange={e =>
                                                                                    handleInputChange(
                                                                                        'skills_required',
                                                                                        e
                                                                                            .target
                                                                                            .value,
                                                                                        index
                                                                                    )
                                                                                }
                                                                            />
                                                                            {index >
                                                                                0 && (
                                                                                <button
                                                                                    type="button"
                                                                                    onClick={() =>
                                                                                        removeSkill(
                                                                                            index
                                                                                        )
                                                                                    }
                                                                                    className="ml-2 text-red-500"
                                                                                >
                                                                                    Remove
                                                                                </button>
                                                                            )}
                                                                        </div>
                                                                    )
                                                                )}
                                                                <button
                                                                    type="button"
                                                                    onClick={
                                                                        addSkill
                                                                    }
                                                                    className="mt-2 text-blue-500"
                                                                >
                                                                    Add Skill
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="px-4 ">
                                                            <TextArea
                                                                isLoading={
                                                                    loadingCreateSuggestedTopic
                                                                }
                                                                placeholder="Description"
                                                                label="Description"
                                                                className=" h-36"
                                                                error={
                                                                    formState
                                                                        .errors
                                                                        .description
                                                                }
                                                                registration={register(
                                                                    'description'
                                                                )}
                                                            />
                                                        </div>

                                                        <div className="my-3 flex justify-end">
                                                            <Button
                                                                disabled={
                                                                    loadingCreateSuggestedTopic
                                                                }
                                                            >
                                                                Submit
                                                            </Button>
                                                        </div>
                                                    </>
                                                )}
                                            </Form>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition>
            </div>
        </div>
    );
}
