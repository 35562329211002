import React, { useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { z } from 'zod';
import Button from '@/components/button';
import { Form } from '@/components/form';
import { InputField, SelectField, TextArea } from '@/components/input';
import IconPencil from '@/components/Icon/IconPencil';
import { EditAddress } from '@/core/hooks/address';
import { EditAcademicInfo } from '@/core/hooks/academics';
import { AcademicInfoPayload } from '@/core/types/academic';
import { TopicPayload } from '@/core/types/topics';
import { EditTopicInfo } from '@/core/hooks/topics';
import ReactQuill from 'react-quill';
import { GetApplications } from '@/core/hooks/application';
import { GetCategories } from '@/core/hooks/category';
import { CategoryType } from '@/core/types/category';

const topicSchema = z.object({
    name: z.string().min(1, { message: 'Topic name is required' }),
    description: z.string().min(1, { message: 'Description is required' }),
    type: z.string().min(1, { message: 'Type is required' }),
    applicationId: z.string().min(1, { message: 'Application is required' }),

});

interface UpdateTopicInfoProps {
    refresh: (prev: any) => void;
    topicInfo: TopicPayload;
}

export const UpdateTopicInfo: React.FC<UpdateTopicInfoProps> = ({
    refresh,
    topicInfo,
}) => {
    const [value, setValue] = useState('Add Instructions here '
    )
    const [modal, setModal] = React.useState(false);
    const { loadingEditTopic, handleEditTopic } = EditTopicInfo();

    const { loadingApplications, handleGetApplications, applications } =
        GetApplications();

    const { loadingCategories, handleGetCategories, categories } =
        GetCategories();

    const query = location.search;
     
    useEffect(() => {
        handleGetApplications(query);
        handleGetCategories(query);
    }, [query]);
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    return (
        <div className="">
            <div className="flex items-center justify-center">
                <button
                    type="button"
                    onClick={() => setModal(true)}
                    className="btn-warning btn text-white px-4 py-2 rounded-full "
                >
                    <IconPencil className="w-4" />
                </button>
            </div>
            <Transition appear show={modal} as={Fragment}>
                <Dialog as="div" open={modal} onClose={() => setModal(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0" />
                    </Transition.Child>
                    <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                        <div className="flex items-start justify-center min-h-screen px-4">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    as="div"
                                    className="panel border-0 p-0 rounded-lg overflow-hidden my-8 w-full max-w-2xl text-black dark:text-white-dark"
                                >
                                    <div className="flex bg-[#fbfbfb] dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                                        <div className="text-lg flex flex-row justify-center w-full">
                                            Update Topic
                                        </div>
                                    </div>
                                    <div className="p-5">
                                        <Form<TopicPayload, typeof topicSchema>
                                            schema={topicSchema}
                                            onSubmit={async data => {
                                                data.categoryIds =
                                                    selectedCategories;
                                                    data.instruction_note = value
                                                await handleEditTopic(
                                                    data,
                                                    topicInfo?.id
                                                );
                                                refresh((prev: any) => !prev);
                                                setModal(false);
                                            }}
                                            className="mt-3"
                                        >
                                            {({ register, formState }) => (
                                                <>
                                                    <div className="px-4 grid grid-cols-2 gap-4 text-sm mb-3">
                                                        <InputField
                                                            type="text"
                                                            isLoading={
                                                                loadingEditTopic
                                                            }
                                                            placeholder="name of Application"
                                                            label="Application Title"
                                                            defaultValue={
                                                                topicInfo.name
                                                            }
                                                            error={
                                                                formState.errors
                                                                    .name
                                                            }
                                                            registration={register(
                                                                'name'
                                                            )}
                                                        />

                                                        <InputField
                                                            type="text"
                                                            isLoading={
                                                                loadingEditTopic
                                                            }
                                                            placeholder="Type"
                                                            label="Topic Type"
                                                            defaultValue={
                                                                topicInfo.type
                                                            }
                                                            error={
                                                                formState.errors
                                                                    .type
                                                            }
                                                            registration={register(
                                                                'type'
                                                            )}
                                                        />

                                                        <SelectField
                                                            placeholder="Select Application"
                                                            isLoading={
                                                                loadingEditTopic ||
                                                                loadingApplications
                                                            }
                                                            label="Application"
                                                            error={
                                                                formState.errors
                                                                    .applicationId
                                                            }
                                                            defaultValue={
                                                                topicInfo
                                                                    .application
                                                                    ?.id
                                                            }
                                                            options={(
                                                                applications?.list ||
                                                                []
                                                            ).map(
                                                                (app: {
                                                                    id: any;
                                                                    title: any;
                                                                }) => ({
                                                                    value: app?.id,
                                                                    text: app?.title,
                                                                })
                                                            )}
                                                            registration={register(
                                                                'applicationId'
                                                            )}
                                                        />
                                                        <div className="my-5  h-[100px] overflow-y-scroll form-input  ">
                                                            <label htmlFor="select category">
                                                                {' '}
                                                                category
                                                            </label>

                                                            <label className="flex flex-col  mx-2 ">
                                                                {categories?.list.map(
                                                                    (
                                                                        category: CategoryType
                                                                    ) => (
                                                                        <>
                                                                            {' '}
                                                                            <div className="flex flex-row justify-start mb-4">
                                                                                <div>
                                                                                    <input
                                                                                        id={`checkbox-${category.id}`}
                                                                                        type="checkbox"
                                                                                        value={
                                                                                            category.id
                                                                                        }
                                                                                        className="form-checkbox w-4 h-4"
                                                                                        onChange={(
                                                                                            e: React.ChangeEvent<HTMLInputElement>
                                                                                        ) => {
                                                                                            const categoryId =
                                                                                                e
                                                                                                    .target
                                                                                                    .value;
                                                                                            setSelectedCategories(
                                                                                                prevSelected => {
                                                                                                    if (
                                                                                                        prevSelected.includes(
                                                                                                            categoryId
                                                                                                        )
                                                                                                    ) {
                                                                                                        return prevSelected.filter(
                                                                                                            id =>
                                                                                                                id !==
                                                                                                                categoryId
                                                                                                        );
                                                                                                    } else {
                                                                                                        return [
                                                                                                            ...prevSelected,
                                                                                                            categoryId,
                                                                                                        ];
                                                                                                    }
                                                                                                }
                                                                                            );
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                                <div>
                                                                                    <label className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                                                                                        {
                                                                                            category.name
                                                                                        }{' '}
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )
                                                                )}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="px-4 ">
                                                        <TextArea
                                                            isLoading={
                                                                loadingEditTopic
                                                            }
                                                            placeholder="Description"
                                                            label="Description"
                                                            defaultValue={
                                                                topicInfo.description
                                                            }
                                                            className="h-36"
                                                            error={
                                                                formState.errors
                                                                    .description
                                                            }
                                                            registration={register(
                                                                'description'
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="p-4">
                                                        <label className=" mb-1  dark:text-white-light ">
                                                            Instruction Notes
                                                        </label>
                                                        <ReactQuill
                                                            theme="snow"
                                                            defaultValue={
                                                                topicInfo?.instruction_note
                                                            }
                                                            onChange={setValue}
                                                        />
                                                    </div>
                                                    <div className="my-3 flex justify-end">
                                                        <Button
                                                            disabled={
                                                                loadingEditTopic
                                                            }
                                                        >
                                                            Submit
                                                        </Button>
                                                    </div>
                                                </>
                                            )}
                                        </Form>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
};
