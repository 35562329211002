import Button from "@/components/button";
import { TableColumnV2 } from "@/components/datatable/DataTable";
import DataTablev3 from "@/components/datatable/Datatable2";
import { Form } from "@/components/form";
import IconEye from "@/components/Icon/IconEye";
import { InputField, SelectField } from "@/components/input";
import Modal from "@/components/modal";
import { useGetEvaluations } from "@/core/hooks/evaluators";
import { UserFilterPayload, UserType } from "@/core/types/auth";
import { EvaluationType } from "@/core/types/evaluation";
import { PaginationType } from "@/core/types/shared";
import formatDateToLongForm from "@/core/utils/DateFormattter";
import { useAppSelector } from "@/store";
import { FunnelIcon } from "@heroicons/react/24/outline";
import { countries } from "countries-list";
import { useState, useEffect } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { z } from "zod";

export default function AcademicPending() {
    const [filters, setFilters] = useState<UserFilterPayload>({
        from: '',
        to: '',
        country: '',
        gender: '',
    });
    const location = useLocation();
    const query = location.search;
    const navigate = useNavigate();
    const { loadingEvaluations, handleGetEvaluations, evaluations } =
        useGetEvaluations();
    const { userAccount: user }: any = useAppSelector(store => store.user);
    const [evaluatorDetails, setEvaluatorDetails] = useState<UserType | null>(
        null
    );

    const updatePageSizeInQuery = (
        query: string,
        evaluated: string
    ): string => {
        const urlParams = new URLSearchParams(query);
        urlParams.set('isEvaluatedByAcademicManager', evaluated.toString());
        return '?' + urlParams.toString();
    };
    useEffect(() => {
        const updatedQuery = updatePageSizeInQuery(query, 'false');
        handleGetEvaluations(updatedQuery);
    }, [query]);
    useEffect(() => {
        if (user) {
            const foundEvaluator = evaluations?.list.find(
                (evaluation: any) =>
                    evaluation.evaluatorOne?.email === user?.email ||
                    evaluation?.evaluatorTwo?.email === user?.email
            );
            if (foundEvaluator) {
                if (foundEvaluator.evaluatorOne?.email === user?.email) {
                    setEvaluatorDetails(foundEvaluator.evaluatorOne);
                } else {
                    setEvaluatorDetails(foundEvaluator.evaluatorTwo);
                }
            }
        }
    }, [user, evaluations]);

    
    const columns: TableColumnV2<EvaluationType>[] = [
        {
            title: 'Applicant Code',
            accessor: 'submittedApplication.applicant_code',
            render: row => (
                <Link
                    to={`/account/academic_manager/user_details/${row?.submittedApplication?.user.id}/${row?.id}/${row.submittedApplication.application.id}`}
                    className="hover:underline"
                >
                    {row.submittedApplication.applicant_code || '-'}
                </Link>
            ),
        },
        {
            title: 'Highest Qualification',
            accessor: 'submittedApplication.user.academicHistories[0].degree',
            render: row => (
                <p>
                    {row.submittedApplication.user.academicHistories[0]
                        .degree || '-'}
                </p>
            ),
        },
        {
            title: 'Qualification institution',
            accessor:
                'submittedApplication.user.academicHistories[0].institution',
            render: row => (
                <p>
                    {row.submittedApplication.user.academicHistories[0]
                        .institution || '-'}
                </p>
            ),
        },
        {
            title: 'Qualification date',
            accessor: 'submittedApplication.user.academicHistories[0].endDate',
            render: row => (
                <p>
                    {' '}
                    {formatDateToLongForm(
                        row.submittedApplication.user.academicHistories[0]
                            .endDate
                    ) || '-'}
                </p>
            ),
        },

        {
            title: 'Application Name',
            accessor: 'submittedApplication.application.title',
            render: row => (
                <p>{row.submittedApplication.application.title || '-'}</p>
            ),
        },

        {
            title: 'Academic Manager decision',
            accessor: 'academic_manager_decision',
            render: row => (
                <div
                    className={`badge w-fit ${
                        row?.academic_manager_decision === 'reject'
                            ? 'bg-danger'
                            : row?.academic_manager_decision === 'accept'
                            ? 'bg-success'
                            : 'text-black dark:text-white-light'
                    }`}
                >
                    <p>
                        {row?.academic_manager_decision === 'reject'
                            ? 'Rejected'
                            : row?.academic_manager_decision === 'accept'
                            ? 'Accepted'
                            : '-'}
                    </p>
                </div>
            ),
        },
        {
            title: 'Action',
            accessor: 'actions',
            render: row => (
                <p>
                    <button
                        onClick={() => {
                            navigate(
                                `/account/academic_manager/user_details/${row?.submittedApplication?.user.id}/${row?.id}/${row.submittedApplication.application.id}`
                            );
                        }}
                        className="btn btn-primary btn-sm"
                    >
                        <IconEye />
                    </button>
                </p>
            ),
        },
    ];

    return (
        <>
            <div className="my-4">
                <p className="text-lg font-bold">
                    Academic Manager pending{' '}
                </p>
            </div>

            <div>
                <DataTablev3
                    columns={columns}
                    previousPage={evaluations?.previousPage ?? 0}
                    nextPage={evaluations?.nextPage ?? 0}
                    currentPage={evaluations?.currentPage ?? 0}
                    data={evaluations?.list ?? []}
                    total={evaluations?.total ?? 0}
                    lastPage={evaluations?.lastPage ?? 0}
                    isLoading={loadingEvaluations}
                    header={
                        <div className="w-full flex my-3 justify-end items-center"></div>
                    }
                />
            </div>
        </>
    );
}

type FilterProps = {
    filters: UserFilterPayload;
    onFilter: (...args: any) => void;
    data: PaginationType<UserType> | null;
};

function Filter(props: FilterProps) {
    const { onFilter, filters, data } = props;
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const schema = z.object({
        from: z.string().optional(),
        to: z.string().optional(),
    });
    const defaultFilters: UserFilterPayload = {
        from: '',
        to: '',
    };

    const handleOnSubmit = (payload: any) => {
        const searchParams = new URLSearchParams(location.search);
       
        const { from, to, country, gender } = payload;

        if (from) searchParams.set('from', from);
        if (to) searchParams.set('to', to);
        if (country) searchParams.set('country', country);
        if (gender) searchParams.set('gender', gender);
       
        const newSearch = searchParams.toString();
        onFilter(payload);
        navigate(`${location.pathname}?${newSearch}`);

        setOpen(false);
    };

    const resetFilter = () => {
        onFilter(defaultFilters);
        navigate(`${location.pathname}`);
        setOpen(false);
    };

    const countryOptions = Object.values(countries).map(country => ({
        value: country.name,
        text: country.name,
    }));

    return (
        <>
            <div className="w-full justify-end flex flex-wrap">
                <div className="flex flex-row gap-3">
                    <Button
                        title="Filter results"
                        onClick={() => setOpen(true)}
                    >
                        <FunnelIcon className="w-4" /> Filter
                    </Button>
                </div>
            </div>
            <Modal show={open} setShow={setOpen} title={'Filter'}>
                <Form<UserFilterPayload, typeof schema>
                    onSubmit={handleOnSubmit}
                    schema={schema}
                    options={{ defaultValues: filters }}
                >
                    {({ register, formState }) => (
                        <div className="px-3">
                            <div className="flex flex-col justify-between gap-4 ">
                                <InputField
                                    type="date"
                                    error={formState.errors.from}
                                    registration={register('from')}
                                    className="h-12"
                                    label="Start Date"
                                />

                                <InputField
                                    type="date"
                                    error={formState.errors.to}
                                    registration={register('to')}
                                    className="h-12"
                                    label="End Date"
                                />

                                <SelectField
                                    placeholder="Select Country"
                                    label="Country"
                                    registration={register('country')}
                                    options={countryOptions}
                                />

                                <SelectField
                                    placeholder="Select Gender"
                                    label="Gender"
                                    registration={register('gender')}
                                    options={[
                                        { value: 'male', text: 'Male' },
                                        { value: 'female', text: 'Female' },
                                    ]}
                                />
                            </div>
                            <div className="py-4 flex flex-row justify-between">
                                <Button
                                    onClick={resetFilter}
                                    background="bg-gray-500"
                                >
                                    Reset
                                </Button>
                                <Button>Filter</Button>
                            </div>
                        </div>
                    )}
                </Form>
            </Modal>
        </>
    );
}