import React, { useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { z } from 'zod';
import Button from '@/components/button';
import { Form } from '@/components/form';
import { SelectField } from '@/components/input';
import IconPencil from '@/components/Icon/IconPencil';
import { GetApplications, useAssignEvaluetor } from '@/core/hooks/application';
import { ApplicationType } from '@/core/types/application';
import { AssignAllApplications, useGetEvaluators } from '@/core/hooks/evaluators';
import { useGetAllUsers } from '@/core/hooks/auth';
import { UserType } from '@/core/types/auth';

const ApplicationSchema = z.object({
    title: z.string().min(1, { message: 'Title is required' }),
    description: z.string().min(1, { message: 'Description is required' }),
    maximum_topic_number: z
        .string()
        .min(1, { message: 'Topic Numbers is required' }),
    allow_suggested_topic: z
        .string()
        .min(1, { message: 'Allow SuggestedTopic  is required' }),
    starting_date: z.string().min(1, { message: 'Start Date is required' }),
    ending_date: z.string().min(1, { message: 'End Date is required' }),
});


export const AssignApplication = () => {
    const [modal, setModal] = React.useState(false);
    const query = location.search;
    const pageSize = 1000000;
    const { loadingApplications, handleGetApplications, applications } = GetApplications();
    const { loadingEvaluators, handleGetEvaluators, evaluators } = useGetEvaluators();
    const { handleAssinfApplications, loadingAssign } = AssignAllApplications();
    const [selectedApplication, setSelectedApplication] = useState<string>('');
    const [selectedUserId, setSelectedUserId] = useState<string>('');
    const [selectedTopic, setSelectedTopic] = useState<string>('');
    const [userIdError, setUserIdError] = useState<string>('');
    const [applicationIdError, setApplicationIdError] = useState<string>('');
    const [topics, setTopics] = useState<Array<{ value: string; text: string }>>([]);

    const updatePageSizeInQuery = (query: string | string[][] | Record<string, string> | URLSearchParams | undefined, pageSize: number) => {
        const urlParams = new URLSearchParams(query);
        urlParams.set('pageSize', pageSize.toString());
        return '?' + urlParams.toString();
    };

    useEffect(() => {
        const updatedQuery = updatePageSizeInQuery(query, pageSize);
        handleGetEvaluators(updatedQuery);
        handleGetApplications(updatedQuery);
    }, []);

    const handleFormSubmit = async (event: any) => {
       
        await handleAssinfApplications(selectedTopic);
        setModal(false);
    };

    const handleApplicationChange = (e: any) => {
        setSelectedApplication(e.target.value);
        // Fetch topics based on selected application
        // Mock fetching topics, replace this with your actual fetch logic
        const fetchedTopics = applications?.list
            .find((app: ApplicationType) => app.id === e.target.value)
            ?.topics.map((topic: any) => ({ value: topic.id, text: topic.name })) || [];
        setTopics(fetchedTopics);
    };

   
    return (
        <div className="">
            <div className="flex items-center justify-center">
                <button
                    type="button"
                    onClick={() => setModal(true)}
                    className="btn btn-primary"
                >
                    Assign application
                </button>
            </div>
            <Transition appear show={modal} as={Fragment}>
                <Dialog as="div" open={modal} onClose={() => setModal(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0" />
                    </Transition.Child>
                    <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                        <div className="flex items-start justify-center min-h-screen px-4">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    as="div"
                                    className="panel border-0 p-0 rounded-lg overflow-hidden my-8 w-full max-w-4xl text-black dark:text-white-dark"
                                >
                                    <div className="flex bg-[#fbfbfb] dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                                        <div className="text-lg flex flex-row justify-center w-full">
                                            Assign Application for evaluation
                                        </div>
                                    </div>
                                    <div className="p-5">
                                        <Form onSubmit={handleFormSubmit}>
                                            {({ register, formState }) => (
                                                <div>
                                                    <SelectField
                                                        placeholder="Select Application"
                                                        isLoading={loadingApplications}
                                                        label="Application"
                                                        options={(applications?.list || []).map((application: ApplicationType) => ({
                                                            value: application?.id,
                                                            text: application?.title,
                                                        }))}
                                                        onChange={handleApplicationChange}
                                                    />
                                                    {applicationIdError && (
                                                        <span className="text-red-500">
                                                            {applicationIdError}
                                                        </span>
                                                    )}
                                                    <SelectField
                                                        placeholder="Select Topic"
                                                        isLoading={loadingApplications}
                                                        label="Topic"
                                                        options={topics}
                                                        onChange={e => setSelectedTopic(e.target.value)}
                                                    />
                                                    <div className="my-3 flex justify-end">
                                                        <Button
                                                            type="submit"
                                                            disabled={loadingEvaluators || loadingApplications || loadingAssign}
                                                        >
                                                            Assign
                                                        </Button>
                                                    </div>
                                                </div>
                                            )}
                                        </Form>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
};

