import Button from '@/components/button';
import { Form } from '@/components/form';
import { InputField, SelectField, TextArea } from '@/components/input';
import {
    AddPersonalInfo,
    useGetMyPersonalInfo,
} from '@/core/hooks/personal_information';
import { PersonalInfoPayload, PersonalType } from '@/core/types/personal_info';
import { useEffect, useState } from 'react';
import { z } from 'zod';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { countries } from 'countries-list';
import {
    AcademicCapIcon,
    HomeIcon,
    SparklesIcon,
    SpeakerWaveIcon,
} from '@heroicons/react/24/outline';
import IconPlayCircle from '@/components/Icon/IconPlayCircle';
import IconLayoutGrid from '@/components/Icon/IconLayoutGrid';
import CreateAddress from '../address';
import AcademicInfo from '../academics';
import ExperiencesInfo from '../experience';
import LanguageInfo from '../languages';
import ReferenceInfo from '../references';
import IconMenuScrumboard from '@/components/Icon/Menu/IconMenuScrumboard';
import IconUser from '@/components/Icon/IconUser';
import { CircularProgress } from '@mui/material';
import { GetCategories } from '@/core/hooks/category';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setPageTitle } from '@/store/themeConfigSlice';
import IconCloudDownload from '@/components/Icon/IconCloudDownload';
import FileUpload from '../upload';
import SocioEconomic from '../socioEconomic';

export type PersonalInputType = {
    firstName: string;
    middleName: string;
    lastName: string;
    date_of_birth: string;
    gender: string;
    maritalStatus: string;
    phoneNumber: string;
    linkedIn: string;
    facebook: string;
    twitter: string;
    email: string;
    Nationality: string;
    citizenship: string;
    hasPassport: boolean;
    passportNumber: string;
    passport_expired_data: string;
    disability_status: string;
    disability_accommodations: string;
    refugee_status: string;
    refugee_support_details: string;
    categoryId: string;
};

function CreatePersonalInfo({ activeTab2, setActiveTab2 }: any) {
    const {
        handleGetMyPersonalInfo,
        loadingInformation,
        personalInfo: data,
    } = useGetMyPersonalInfo();
    const { handleAddPersonalInfo, LoadingAddPersoanlInfo } = AddPersonalInfo();
    const { loadingCategories, handleGetCategories, categories } =
        GetCategories();

    const [validationErrors, setValidationErrors] = useState<any>();
    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState<PersonalInputType>({
        firstName: '',
        middleName: '',
        lastName: '',
        date_of_birth: '',
        gender: '',
        maritalStatus: '',
        phoneNumber: '',
        linkedIn: '',
        facebook: '',
        twitter: '',
        email: '',
        Nationality: '',
        citizenship: '',
        hasPassport: false,
        passportNumber: '',
        passport_expired_data: '',
        disability_status: 'no',
        disability_accommodations: '',
        refugee_status: 'no',
        refugee_support_details: '',
        categoryId: '',
    });

    useEffect(() => {
        if (data) {
            setFormData({
                firstName: data.firstName || '',
                middleName: data.middleName || '',
                lastName: data.lastName || '',
                date_of_birth: data.date_of_birth || '',
                gender: data.gender || '',
                maritalStatus: data.maritalStatus || '',
                phoneNumber: data.phoneNumber || '',
                linkedIn: data.linkedIn || '',
                facebook: data.facebook || '',
                twitter: data.twitter || '',
                email: data.email || '',
                Nationality: data.Nationality || '',
                citizenship: data.citizenship || '',
                hasPassport: data.hasPassport || false,
                passportNumber: data.passportNumber || '',
                passport_expired_data: data.passport_expired_data || '',
                disability_status: data.disability_status || 'no',
                disability_accommodations: data.disability_accommodations || '',
                refugee_status: data.refugee_status || 'no',
                refugee_support_details: data.refugee_support_details || '',
                categoryId: data.categoryId || '',
            });
        }
    }, [data]);

    const dispatch = useDispatch();
    useEffect(() => {
        handleGetMyPersonalInfo();
        handleGetCategories();
        dispatch(setPageTitle('Personal Information'));
    }, [dispatch]);

    const countryOptions = Object.values(countries).map(country => ({
        value: country.name,
        text: country.name,
    }));

    const [showDisabilityAccommodations, setShowDisabilityAccommodations] =
        useState(formData.disability_status === 'yes');
    const [showRefugeeSupportDetails, setShowRefugeeSupportDetails] = useState(
        formData.refugee_status === 'refugee'
    );
    useEffect(() => {
        setShowDisabilityAccommodations(formData.disability_status === 'yes');
        setShowRefugeeSupportDetails(formData.refugee_status === 'refugee');
    }, [formData.disability_status, formData.refugee_status]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);

        const errors: any = [];

        

        if (!formData.firstName) errors.push({ field: 'firstName', message: 'First name is required' });
        if (!formData.lastName) errors.push({ field: 'lastName', message: 'Last name is required' });
        if (!formData.date_of_birth) errors.push({ field: 'date_of_birth', message: 'Date of birth is required' });
        if (!formData.gender || !['male', 'female', 'other', 'prefer_not_to_say'].includes(formData.gender))
            errors.push({ field: 'gender', message: 'Gender must be a valid option' });

        if (!formData.maritalStatus || !['single', 'married', 'separated', 'divorced', 'widowed', 'prefer_not_to_say'].includes(formData.maritalStatus))
            errors.push({ field: 'maritalStatus', message: 'Marital status must be a valid option' });

        if (!formData.Nationality) errors.push({ field: 'Nationality', message: 'Nationality is required' });
        if (!formData.citizenship) errors.push({ field: 'citizenship', message: 'Citizenship is required' });
    
   
        setValidationErrors(errors);
       
    
        if (Object.keys(errors).length > 0) {
            setLoading(false);
            return;
        }

        try {
            await handleAddPersonalInfo(formData, data?.id);
            setActiveTab2(2);
        } catch (error) {
            console.error('Error submitting form', error);
        } finally {
            setLoading(false);
        }

    };
    const [PhoneCountry, setPhoneCountry] = useState<string>('250');
    const [selectedCountry, setSelectedCountry] = useState<string>(
        formData?.Nationality || ''
    );
    function findCountryByName(countriesObject:any, countryName:any) {
        for (const countryCode in countriesObject) {
          if (countriesObject.hasOwnProperty(countryCode)) {
            const country = countriesObject[countryCode];
            if (country.name === countryName) {
              return country;
            }
          }
        }
        return null; 
      }
    useEffect(() => {
      const country = findCountryByName(countries, selectedCountry);


      setPhoneCountry(country?.phone[0])
      
    }, [selectedCountry,countries])
    const [phone, setPhone] = useState(data?.phoneNumber || '');

    const getErrorMessage = (field: string) => validationErrors?.find((err: any) => err.field === field)?.message;

    return (
        <div className="panel">
            <form>
                {loadingInformation ? (
                    <CircularProgress />
                ) : (
                    <div className="px-4 flex flex-col gap-4 text-sm mb-3">
                        <div className="flex flex-col md:flex-row gap-2">
                            <InputField
                                type="text"
                                label="First Name"
                                defaultValue={formData.firstName}
                                onChange={e =>
                                    setFormData({
                                        ...formData,
                                        firstName: e.target.value,
                                    })
                                }
                                error={getErrorMessage('lastName')}
                            />
                            <InputField
                                type="text"
                                label="Middle Name"
                                defaultValue={formData.middleName}
                                onChange={e =>
                                    setFormData({
                                        ...formData,
                                        middleName: e.target.value,
                                    })
                                }
                            />
                            <InputField
                                type="text"
                                label="Last Name"
                                defaultValue={formData.lastName}
                                onChange={e =>
                                    setFormData({
                                        ...formData,
                                        lastName: e.target.value,
                                    })
                                }
                                error={
                                    validationErrors?.find(
                                        (err: any) => err.field === 'lastName'
                                    )?.message
                                }
                            />
                        </div>

                        <div className="flex flex-col md:flex-row gap-2">
                            <InputField
                                type="date"
                                label="Date of Birth"
                                defaultValue={formData.date_of_birth}
                                onChange={e =>
                                    setFormData({
                                        ...formData,
                                        date_of_birth: e.target.value,
                                    })
                                }
                                error={
                                    validationErrors?.find(
                                        (err: any) =>
                                            err.field === 'date_of_birth'
                                    )?.message
                                }
                            />
                            <SelectField
                                label="Gender"
                                placeholder="Select Gender"
                                defaultValue={formData.gender}
                                options={[
                                    { value: 'male', text: 'Male' },
                                    { value: 'female', text: 'Female' },
                                    {
                                        value: 'prefer_not_to_say',
                                        text: 'Prefer not to say',
                                    },
                                ]}
                                onChange={e =>
                                    setFormData({
                                        ...formData,
                                        gender: e.target.value,
                                    })
                                }
                                error={
                                    validationErrors?.find(
                                        (err: any) => err.field === 'gender'
                                    )?.message
                                }
                            />
                            <SelectField
                                label="Marital Status"
                                placeholder="Select Marital Status"
                                defaultValue={formData.maritalStatus}
                                options={[
                                    { value: 'single', text: 'Single' },
                                    { value: 'married', text: 'Married' },
                                    { value: 'divorced', text: 'Divorced' },
                                    { value: 'widowed', text: 'Widowed' },
                                    {
                                        value: 'prefer_not_to_say',
                                        text: 'Prefer not to say',
                                    },
                                ]}
                                onChange={e =>
                                    setFormData({
                                        ...formData,
                                        maritalStatus: e.target.value,
                                    })
                                }
                                error={
                                    validationErrors?.find(
                                        (err: any) =>
                                            err.field === 'maritalStatus'
                                    )?.message
                                }
                            />
                        </div>

                        <div className="flex flex-col md:flex-row gap-2">
                        <InputField 
                        placeholder='Phone Number'
                        label='Phone Number'
                                            defaultValue={formData?.phoneNumber }
                                                className="md:w-[240px] w-full  "
                                                onChange={phone =>
                                                    setPhone(phone)
                                                }
                                            />
                            <InputField
                                type="email"
                                label="Email"
                                defaultValue={formData.email}
                                onChange={e =>
                                    setFormData({
                                        ...formData,
                                        email: e.target.value,
                                    })
                                }
                            />
                        </div>

                        <div className="flex flex-col md:flex-row gap-2">
                            <InputField
                                type="text"
                                label="LinkedIn"
                                defaultValue={formData.linkedIn}
                                onChange={e =>
                                    setFormData({
                                        ...formData,
                                        linkedIn: e.target.value,
                                    })
                                }
                            />
                            <InputField
                                type="text"
                                label="Facebook"
                                defaultValue={formData.facebook}
                                onChange={e =>
                                    setFormData({
                                        ...formData,
                                        facebook: e.target.value,
                                    })
                                }
                            />
                            <InputField
                                type="text"
                                label="Twitter"
                                defaultValue={formData.twitter}
                                onChange={e =>
                                    setFormData({
                                        ...formData,
                                        twitter: e.target.value,
                                    })
                                }
                            />
                        </div>

                        <div className="flex flex-col md:flex-row gap-2">
                            <SelectField
                                label="Nationality"
                                placeholder=""
                                defaultValue={formData.Nationality}
                                options={countryOptions}
                                onChange={e =>
                                    setFormData({
                                        ...formData,
                                        Nationality: e.target.value,
                                    })
                                }
                                error={
                                    validationErrors?.find(
                                        (err: any) =>
                                            err.field === 'Nationality'
                                    )?.message
                                }
                            />
                            <SelectField
                                label="Citizenship"
                                placeholder="Select Citizenship"
                                defaultValue={formData.citizenship}
                                options={countryOptions}
                                onChange={e =>
                                    setFormData({
                                        ...formData,
                                        citizenship: e.target.value,
                                    })
                                }
                                error={
                                    validationErrors?.find(
                                        (err: any) =>
                                            err.field === 'citizenship'
                                    )?.message
                                }
                            />
                        </div>

                        <SelectField
                            label="Category"
                            placeholder="Select Category"
                            defaultValue={formData.categoryId}
                            options={(categories?.list || []).map(
                                (cat: any) => ({
                                    value: cat.id,
                                    text: cat.name,
                                })
                            )}
                            onChange={e =>
                                setFormData({
                                    ...formData,
                                    categoryId: e.target.value,
                                })
                            }
                        />

                        <div className="flex flex-col md:flex-row gap-2">
                            <SelectField
                                label="Has Passport"
                                placeholder="Select Passport"
                                defaultValue={formData.hasPassport.toString()}
                                options={[
                                    { value: 'true', text: 'Yes' },
                                    { value: 'false', text: 'No' },
                                ]}
                                onChange={e =>
                                    setFormData({
                                        ...formData,
                                        hasPassport: e.target.value === 'true',
                                    })
                                }
                            />
                            {formData.hasPassport && (
                                <>
                                    <InputField
                                        type="text"
                                        label="Passport Number"
                                        defaultValue={formData.passportNumber}
                                        onChange={e =>
                                            setFormData({
                                                ...formData,
                                                passportNumber: e.target.value,
                                            })
                                        }
                                    />
                                    <InputField
                                        type="date"
                                        label="Passport Expiry Date"
                                        defaultValue={
                                            formData.passport_expired_data
                                        }
                                        onChange={e =>
                                            setFormData({
                                                ...formData,
                                                passport_expired_data:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </>
                            )}
                        </div>

                        <div className="flex flex-col gap-2">
                            <SelectField
                                label="Disability Status"
                                placeholder="Select Disability Status"
                                defaultValue={formData.disability_status}
                                options={[
                                    { value: 'yes', text: 'Yes' },
                                    { value: 'no', text: 'No' },
                                ]}
                                onChange={e =>
                                    setFormData({
                                        ...formData,
                                        disability_status: e.target.value,
                                    })
                                }
                            />
                            {showDisabilityAccommodations && (
                                <>
                                    <p className="mb-2  font-semibold">
                                        If you are disabled, please specify the
                                        type of support or accommodations you
                                        may require. Examples include:
                                    </p>
                                    <ul className="list-disc pl-6 space-y-2 text-gray-600">
                                        <li>
                                            Accessible learning materials (e.g.,
                                            large print, screen readers, etc.)
                                        </li>
                                        <li>
                                            Assistive devices (e.g.,
                                            wheelchairs, hearing aids, etc.)
                                        </li>
                                        <li>Mobility support</li>
                                        <li>
                                            Other accommodations (please
                                            specify)
                                        </li>
                                    </ul>
                                    <TextArea
                                        label="Disability Accommodations"
                                        placeholder="If you are disabled, please specify the type of support or accommodations you may require."
                                        defaultValue={
                                            formData.disability_accommodations
                                        }
                                        onChange={e =>
                                            setFormData({
                                                ...formData,
                                                disability_accommodations:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </>
                            )}
                        </div>

                        <div className="flex flex-col  gap-2">
                            <SelectField
                                label="Refugee Status"
                                placeholder="Select Refugee Status"
                                defaultValue={formData.refugee_status}
                                options={[
                                    { value: 'refugee', text: 'Refugee' },
                                    
                                    { value: 'no', text: 'Non Refugee' },
                                    { value: 'internally_displaced', text: 'Internally Displaced' },
                                    {
                                        value: 'prefer_not_to_say',
                                        text: 'Prefer not to say',
                                    },
                                ]}
                                onChange={e =>
                                    setFormData({
                                        ...formData,
                                        refugee_status: e.target.value,
                                    })
                                }
                            />
                            {showRefugeeSupportDetails && (
                                <TextArea
                                    placeholder="If you are a refugee, please provide details of the support you want."
                                    label="Refugee Support Details"
                                    defaultValue={
                                        formData.refugee_support_details
                                    }
                                    onChange={e =>
                                        setFormData({
                                            ...formData,
                                            refugee_support_details:
                                                e.target.value,
                                        })
                                    }
                                />
                            )}
                        </div>

                        <div className="my-3 flex justify-end">
                            <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={loading}
                                onClick={handleSubmit}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                )}
            </form>
        </div>
    );
}

export default function ProfileInfo() {
    const [activeTab2, setActiveTab2] = useState<any>(1);
    const navigate = useNavigate();
    return (
        <div className="inline-block w-full">
            <ul className="mb-5 grid  grid-cols-3 md:grid-cols-6">
                <li>
                    <div
                        className={`${
                            activeTab2 === 1 ? '!bg-primary text-white' : ''
                        }
                    flex items-center justify-center rounded-full bg-[#f3f2ee] p-2.5 dark:bg-[#1b2e4b]`}
                        onClick={() => setActiveTab2(1)}
                    >
                        <HomeIcon className="w-5 h-5 " />
                    </div>
                    <span className="text-center block mt-2">Personal</span>
                </li>
                <li>
                    <div
                        className={`${
                            activeTab2 === 2 ? '!bg-primary text-white' : ''
                        }
                    flex items-center justify-center rounded-full bg-[#f3f2ee] p-2.5 dark:bg-[#1b2e4b]`}
                        onClick={() => setActiveTab2(2)}
                    >
                        <IconLayoutGrid className="h-5 w-5 " />
                    </div>
                    <span className="text-center block mt-2">Address</span>
                </li>
                <li>
                    <div
                        className={`${
                            activeTab2 === 3 ? '!bg-primary text-white' : ''
                        }
                    flex items-center justify-center rounded-full bg-[#f3f2ee] p-2.5 dark:bg-[#1b2e4b]`}
                        onClick={() => setActiveTab2(3)}
                    >
                        <AcademicCapIcon className="h-5 w-5 " />
                    </div>
                    <span
                        className={`text-center block mt-2 ${
                            activeTab2 === 3 ? 'text-primary ' : ''
                        }`}
                    >
                        Academics
                    </span>
                </li>
                <li>
                    <div
                        className={`${
                            activeTab2 === 4 ? '!bg-primary text-white' : ''
                        }
                    flex items-center justify-center rounded-full bg-[#f3f2ee] p-2.5 dark:bg-[#1b2e4b]`}
                        onClick={() => setActiveTab2(4)}
                    >
                        <IconMenuScrumboard className="h-5 w-5 " />
                    </div>
                    <span
                        className={`text-center block mt-2 ${
                            activeTab2 === 4 ? 'text-primary ' : ''
                        }`}
                    >
                        Experience
                    </span>
                </li>
                <li>
                    <div
                        className={`${
                            activeTab2 === 5 ? '!bg-primary text-white' : ''
                        }
                    flex items-center justify-center rounded-full bg-[#f3f2ee] p-2.5 dark:bg-[#1b2e4b]`}
                        onClick={() => setActiveTab2(5)}
                    >
                        <SparklesIcon className="h-5 w-5 " />
                    </div>
                    <span
                        className={`text-center block mt-2 ${
                            activeTab2 === 5 ? 'text-primary ' : ''
                        }`}
                    >
                        Languages
                    </span>
                </li>
                <li>
                    <div
                        className={`${
                            activeTab2 === 6 ? '!bg-primary text-white' : ''
                        }
                    flex items-center justify-center rounded-full bg-[#f3f2ee] p-2.5 dark:bg-[#1b2e4b]`}
                        onClick={() => setActiveTab2(6)}
                    >
                        <IconUser className="h-5 w-5 " />
                    </div>
                    <span
                        className={`text-center block mt-2 ${
                            activeTab2 === 6 ? 'text-primary ' : ''
                        }`}
                    >
                        Social Economic Status
                    </span>
                </li>
                <li>
                    <div
                        className={`${
                            activeTab2 === 7 ? '!bg-primary text-white' : ''
                        }
                    flex items-center justify-center rounded-full bg-[#f3f2ee] p-2.5 dark:bg-[#1b2e4b]`}
                        onClick={() => setActiveTab2(7)}
                    >
                        <IconUser className="h-5 w-5 " />
                    </div>
                    <span
                        className={`text-center block mt-2 ${
                            activeTab2 === 7 ? 'text-primary ' : ''
                        }`}
                    >
                        Reference
                    </span>
                </li>
                <li>
                    <div
                        className={`${
                            activeTab2 === 8 ? '!bg-primary text-white' : ''
                        }
                    flex items-center justify-center rounded-full bg-[#f3f2ee] p-2.5 dark:bg-[#1b2e4b]`}
                        onClick={() => setActiveTab2(8)}
                    >
                        <IconCloudDownload className="h-5 w-5 " />
                    </div>
                    <span
                        className={`text-center block mt-2 ${
                            activeTab2 === 8 ? 'text-primary ' : ''
                        }`}
                    >
                        Upload documents
                    </span>
                </li>
            </ul>
            <div>
                <p className="mb-5">
                    {activeTab2 === 1 && (
                        <CreatePersonalInfo
                            activeTab2={activeTab2}
                            setActiveTab2={setActiveTab2}
                        />
                    )}
                </p>

                <p className="mb-5">{activeTab2 === 2 && <CreateAddress />}</p>

                <p className="mb-5">{activeTab2 === 3 && <AcademicInfo />}</p>
                <p className="mb-5">
                    {activeTab2 === 4 && <ExperiencesInfo />}
                </p>
                <p className="mb-5">{activeTab2 === 5 && <LanguageInfo />}</p>
                <p className="mb-5">{activeTab2 === 6 && <SocioEconomic />}</p>
                <p className="mb-5">{activeTab2 === 7 && <ReferenceInfo />}</p>
                <p className="mb-5">{activeTab2 === 8 && <FileUpload />}</p>
            </div>

            <div className="flex justify-between">
                <button
                    type="button"
                    className={`btn btn-primary ${
                        activeTab2 === 1 ? 'hidden' : ''
                    }`}
                    onClick={() =>
                        setActiveTab2(activeTab2 === 2 ? 1 : activeTab2 - 1)
                    }
                >
                    Back
                </button>
                {activeTab2 !== 1 && activeTab2 !== 8 && (
                    <button
                        type="button"
                        className="btn btn-primary ltr:ml-auto rtl:mr-auto"
                        onClick={() =>
                            setActiveTab2(
                                activeTab2 === 1
                                    ? 2
                                    : activeTab2 === 2
                                    ? 3
                                    : activeTab2 === 3
                                    ? 4
                                    : activeTab2 === 4
                                    ? 5
                                    : activeTab2 === 5
                                    ? 6
                                    : 7
                            )
                        }
                    >
                        Next
                    </button>
                )}

                {activeTab2 === 8 ? (
                    <button
                        type="button"
                        className="btn btn-primary ltr:ml-auto rtl:mr-auto"
                        onClick={() =>
                            navigate('/account/applicant/application')
                        }
                    >
                        Continue
                    </button>
                ) : null}
            </div>
        </div>
    );
}
